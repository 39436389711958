import apiUrl from "../../ApiUrl";
import axios from "axios";
import toastr from "toastr";
import { counUniversityListSuccess, resetUniversities, universityListStart, universityListSuccess } from "../../actions/GetUniversities";
import { getCounsellorCourseStart, getCounsellorCourseSuccess, getCourseStart, getCourseSuccess, resetCourses } from "../../actions/GetCourses";

// export const filterUniversity = (body) => async (dispatch, getState) => {
//     const { destination } = body
//     console.log("inside api button destination", destination);
//     const params = {
//         Destination: destination,
//     }
//     const baseUrl = getState().config.baseUrl


//     try {
//         dispatch(resetUniversities())
//         const response = await axios.get(`${apiUrl}/api/student/University/details`, { params })
//         if (response && response.data && !response.data.error) {
//             console.log("response i am getting for filtering is", response?.data)
//             dispatch(universityListSuccess(response?.data))
//         }
//         else if (response && response.data && response.data.error) {
//             toastr.error(response.data.error)
//         }
//         else {
//             throw new Error('No data received from the server');
//         }
//     }
//     catch (error) {
//         console.log("error is here:- ", error);
//     }

// };

//new api call for the same filter using country
export const filterUniversity = (body) => async (dispatch, getState) => {
    const { destination } = body;
    console.log("inside api button destination", destination);

    try {
        dispatch(resetUniversities());
        const response = await axios.get(`${apiUrl}/api/student/university/filter?Country[]=${destination}`);
        
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for filtering is", response.data);
            dispatch(universityListSuccess(response.data));
        }
        else if (response && response.data && response.data.error) {
            toastr.error(response.data.error);
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
};

export const filterCounUniversity = (body) => async (dispatch, getState) => {
    const { destination, duration, courseLevel } = body
    console.log("inside api button destination", destination);
    const params = {
        Country: destination,
    }
    const baseUrl = getState().config.baseUrl


    try {
        dispatch(resetUniversities())
        const response = await axios.get(`${apiUrl}/api/counsellor/University/filter`, { params })
        // `${apiUrl}/api/course/search?Destination=[${destination[0]}]&Duration=[${duration[0]}]&Course_Level=[${courseLevel[0]}]&page=1&perPage=30`,);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for filtering is", response?.data)
            dispatch(counUniversityListSuccess(response?.data))
        }
        else if (response && response.data && response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }

};

export const resetUniversity = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(resetUniversities());
    dispatch(universityListStart());
    try {
        const response = await axios.get(
            `${apiUrl}/api/student/University/list`
        );
        dispatch(universityListSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};

export const resetCounUniversity = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(resetUniversities());
    dispatch(universityListStart());
    try {
        const response = await axios.get(
            `${apiUrl}/api/counsellor/university/list`
        );
        dispatch(counUniversityListSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};

export const filterCourses = (body,) => async (dispatch, getState) => {
    const { destination, duration, courseLevel } = body
    console.log("inside api button destination", destination);
    console.log("inside api button duration", duration);
    console.log("inside api button course level", courseLevel);

    const params = {};
    params['Destination[]'] = destination.join(',');
    params['Duration[]'] = duration.join(',');
    params['Course_Level[]'] = courseLevel.join(',');

    try {
        dispatch(getCourseStart());
        dispatch(resetCourses());

        const response = await axios.get(`${apiUrl}/api/student/course/search`, { params });

        if (response && response.data && !response.data.error) {
            console.log("response i am getting for filtering is", response?.data);
            dispatch(getCourseSuccess(response?.data));
        }
        else if (response && response.data && response.data.error) {
            toastr.error(response.data.error);
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
};

export const agentFilterCourses = (body,) => async (dispatch, getState) => {
    const { destination, duration, courseLevel } = body
    console.log("inside api button destination", destination);
    console.log("inside api button duration", duration);
    console.log("inside api button course level", courseLevel);

    const params = {};
    params['Destination[]'] = destination.join(',');
    params['Duration[]'] = duration.join(',');
    params['Course_Level[]'] = courseLevel.join(',');

    try {
        dispatch(getCourseStart());
        dispatch(resetCourses());

        const response = await axios.get(`${apiUrl}/api/course/search`, { params });

        if (response && response.data && !response.data.error) {
            console.log("response i am getting for filtering is", response?.data);
            dispatch(getCourseSuccess(response?.data));
        }
        else if (response && response.data && response.data.error) {
            toastr.error(response.data.error);
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
};

export const filterCounCourses = (body) => async (dispatch, getState) => {
    const { destination, duration, courseLevel } = body
    console.log("inside api button destination", destination);
    console.log("inside api button duration", duration);
    console.log("inside api button course level", courseLevel);

    const params = {};
    params['Destination[]'] = destination.join(',');
    params['Duration[]'] = duration.join(',');
    params['Course_Level[]'] = courseLevel.join(',');

    console.log("params", params);

    try {
        dispatch(getCounsellorCourseStart());
        dispatch(resetCourses());

        const response = await axios.get(`${apiUrl}/api/counsellor/course-filter`, { params });

        if (response && response.data && !response.data.error) {
            console.log("response i am getting for filtering is", response?.data);
            dispatch(getCounsellorCourseSuccess(response?.data));
        }
        else if (response && response.data && response.data.error) {
            toastr.error(response.data.error);
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
};

export const resetCourse = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    // dispatch(resetCourses());
    dispatch(getCourseStart());
    try {
        const response = await axios.get(`${apiUrl}/api/course`);
        dispatch(getCourseSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};

export const resetCounCourse = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(resetCourses());
    dispatch(getCourseStart());
    try {
        const response = await axios.get(`${apiUrl}/api/counsellor/course-list`);
        dispatch(getCounsellorCourseSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};