import React from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'

function ViewReferenceDetails({ setActiveTab }) {
    const metaFields = useSelector(state => state.metaFields?.fieldsList?.fields)
    const referenceData = useSelector(state => state.applicationDetails?.applicationDetails?.data)

    console.log("referenceData are:- ", referenceData)
    return (

        <div
            className="tab-pane fade show active"
            id="pills-viewreferences"
            role="tabpanel"
            aria-labelledby="pills-viewreferences-tab"
        >
            <form >

                <div className="form-area">
                    <div className="form-head">
                        Your Academic Details {" "}
                        <span>We will use your academic details while processing your applications</span>
                    </div>
                    <div className="form-subhead">
                        <hr />
                    </div>
                    <div className="row clearfix">
                        <div className="col-12">
                            <label className='form-check-label' htmlFor='courseChoosen'>Choose Course</label>
                            <input
                                type="text"
                                id='courseChoosen'
                                name="courseChoosen"
                                className="form-control"
                                placeholder="Course level"
                                value={referenceData && (referenceData[0]?.Select_Program?.["name"] + " (" + referenceData[0]?.University_Name + ")")}
                                // value={selectedCourse?.level || "NA"}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='Course_Duration' className='form-check-label'>Duration</label>
                            <input
                                type="text"
                                id='Course_Duration'
                                name="Course_Duration"
                                className="form-control"
                                placeholder="Duration"
                                value={referenceData && (referenceData[0]?.Course_Duration || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='Country' className='form-check-label'>Country</label>
                            <input
                                type="text"
                                id='Country'
                                name="Country"
                                className="form-control"
                                placeholder="Country"
                                value={referenceData && (referenceData[0]?.Country || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='courselevel' className='form-check-label'>Course level</label>
                            <input
                                type="text"
                                id='courselevel'
                                name="courselevel"
                                className="form-control"
                                placeholder="Course level"
                                value={referenceData && (referenceData[0]?.Course_Level || "NA")}
                                disabled
                            />
                        </div>
                        {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='Course_Status' className='form-check-label'>Type</label>
                            <input
                                type="text"
                                id='Course_Status'
                                name="Course_Status"
                                className="form-control"
                                placeholder="course_status"
                                value={referenceData && (referenceData[0]?.Course_Status || "NA")}
                                disabled
                            />
                        </div> */}
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Preferred_intake'>Choose preferred in take</label>
                            <input
                                type="text"
                                id='Preferred_intake'
                                name="Preferred_intake"
                                className="form-control"
                                placeholder="Preferred_intake"
                                value={referenceData && (referenceData[0]?.Preferred_intake || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='Last_Qualification' className='form-check-label'>Last qualification</label>
                            <input type="text" name="Last_Qualification" id="Last_Qualification" className="form-control" placeholder="Last qualification"
                                value={referenceData && (referenceData[0]?.Last_Qualification || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label htmlFor='IELTS_PTE_score' className='form-check-label'>IELTS PTE Score</label>
                            <input
                                type="text"
                                id='IELTS_PTE_score'
                                name="IELTS_PTE_score"
                                className="form-control"
                                placeholder="IELTS PTE Score"
                                value={referenceData && (referenceData[0]?.IELTS_PTE_score || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='English_Language_Certificate_IF_ANY'>Do you have any English language certificate</label>
                            <input
                                type="text"
                                id='English_Language_Certificate_IF_ANY'
                                name="English_Language_Certificate_IF_ANY"
                                className="form-control"
                                placeholder="English_Language_Certificate_IF_ANY"
                                value={referenceData && (referenceData[0]?.English_Language_Certificate_IF_ANY || "NA")}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label' htmlFor='Were_you_introduced_by_an_agent'>Are you introduced by an agent or counsellor?</label>
                            <input
                                type="text"
                                id='Were_you_introduced_by_an_agent'
                                name="Were_you_introduced_by_an_agent"
                                className="form-control"
                                placeholder="Were_you_introduced_by_an_agent"
                                value={referenceData && (referenceData[0]?.Were_you_introduced_by_an_agent || "NA")}
                                disabled

                            />
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("viewaddress") }}>Previous</button>
                                <button className="btn btn-theme " onClick={() => { setActiveTab("viewemployment") }}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form >
        </div >

    )
}

export default ViewReferenceDetails