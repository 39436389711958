import React, { useEffect, useState } from 'react';
import { agentFilterCourses, filterCounCourses, filterCourses, resetCounCourse, resetCourse } from '../../../@redux/dispatch/filters';
import { useDispatch, useSelector } from 'react-redux';
import { universityCountryList } from '../../../@redux/dispatch/GetUniversitiesReducer';
import { getCourseAttributeList } from '../../../@redux/dispatch/course';

function CourseFilter({ userRole }) {
    const [destination, setDestination] = useState([]);
    const [duration, setDuration] = useState([]);
    const [courseLevel, setCourseLevel] = useState([]);
    const [showMoreCountries, setShowMoreCountries] = useState(false);
    const [showMoreDuration, setShowMoreDuration] = useState(false);
    const [showMoreCourseLevel, setShowMoreCourseLevel] = useState(false);
    const dispatch = useDispatch();
    const [countries, setCountries] = useState([]);
    const countryList = useSelector((state) => state?.universityList?.countryUniversityList?.countries);
    const courseDurationList = useSelector((state) => state?.courseAttributeList?.courseDurationList);
    const courseLevelList = useSelector((state) => state?.courseAttributeList?.courseLevelList);

    useEffect(() => {
        dispatch(universityCountryList());
        dispatch(getCourseAttributeList());
    }, [dispatch]);

    useEffect(() => {
        if (Array.isArray(countryList)) {
            setCountries(countryList);
        }
    }, [countryList]);

    const handleDestinationChange = (e) => {
        const { name, checked } = e.target;
        const newDestination = checked ? 
            [...destination, name] : 
            destination.filter(dest => dest !== name);
        setDestination(newDestination);
        handleFilterChange(newDestination, duration, courseLevel);
    };

    const handleDurationChange = (e) => {
        const { name, checked } = e.target;
        const newDuration = checked ? 
            [...duration, name] : 
            duration.filter(dur => dur !== name);
        setDuration(newDuration);
        handleFilterChange(destination, newDuration, courseLevel);
    };

    const handleCourseChange = (e) => {
        const { name, checked } = e.target;
        const newCourseLevel = checked ? 
            [...courseLevel, name] : 
            courseLevel.filter(level => level !== name);
        setCourseLevel(newCourseLevel);
        handleFilterChange(destination, duration, newCourseLevel);
    };

    const handleFilterChange = (dest, dur, level) => {
        if (userRole === 'student') {
            dispatch(filterCourses({ destination: dest, duration: dur, courseLevel: level }));
        } else if(userRole === 'agent'){
            dispatch(agentFilterCourses({ destination: dest, duration: dur, courseLevel: level }));
        } else {
            dispatch(filterCounCourses({ destination: dest, duration: dur, courseLevel: level }));
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        setDestination([]);
        setDuration([]);
        setCourseLevel([]);
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false);
        if (userRole !== 'counsellor') {
            dispatch(resetCourse());
        } else {
            dispatch(resetCounCourse());
        }
    };

    const renderCountries = () => {
        if (!Array.isArray(countries)) return null;
        const displayCountries = showMoreCountries ? countries : countries.slice(0, 4);
        return displayCountries.map((country, index) => (
            <div className="form-check" key={index}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    name={country}
                    id={country}
                    onChange={handleDestinationChange}
                />
                <label className="form-check-label" htmlFor={country}>
                    {country}
                </label>
            </div>
        ));
    };

    const renderDuration = () => {
        if (!Array.isArray(courseDurationList)) return null;
        const displayDurations = showMoreDuration ? courseDurationList : courseDurationList.slice(0, 4);
        return displayDurations.map((dur, index) => (
            <div className="form-check d-flex align-items-start" key={index}>
                <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    name={dur}
                    id={dur}
                    onChange={handleDurationChange}
                />
                <label 
                    className="form-check-label ms-2" 
                    htmlFor={dur}
                    style={{
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        maxWidth: 'calc(100% - 25px)',
                        lineHeight: '1.4'
                    }}
                >
                    {dur}
                </label>
            </div>
        ));
    };

    const renderCourseLevel = () => {
        if (!Array.isArray(courseLevelList)) return null;
        const displayLevels = showMoreCourseLevel ? courseLevelList : courseLevelList.slice(0, 4);
        return displayLevels.map((level, index) => (
            <div className="form-check d-flex align-items-start" key={index}>
                <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    name={level}
                    id={level}
                    onChange={handleCourseChange}
                />
                <label 
                    className="form-check-label ms-2" 
                    htmlFor={level}
                    style={{
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        maxWidth: 'calc(100% - 25px)',
                        lineHeight: '1.4'
                    }}
                >
                    {level}
                </label>
            </div>
        ));
    };

    return (
        <div className="col-12 col-lg-12 col-xl-2 col-xxl-2 filter-desktop customize-mobile p-1">
            <div className="f-title">
                <span id="filter-close">
                    <i className="bi bi-chevron-left" />
                </span>
                Filters
                <span className="reset-button desktop">
                    <input type="button" name="reset" defaultValue="Reset" onClick={handleReset} />
                </span>
            </div>
            <div className="form-fields">
                <h6 className="filter-title">Destination</h6>
                <div className="checkboxes filter-checkboxes">
                    {renderCountries()}
                    {countries?.length > 4 && (
                        <button 
                            type="button"
                            style={{
                                border: 'none',
                                background: 'none',
                                color: '#6c63ff',
                                fontSize: '0.9rem',
                                padding: '4px 0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowMoreCountries(!showMoreCountries)}
                        >
                            {showMoreCountries ? 'Show Less' : 'Show More'}
                            <i className={`bi bi-chevron-${showMoreCountries ? 'up' : 'down'}`} />
                        </button>
                    )}
                </div>
                <h6 className="filter-title">Duration</h6>
                <div className="checkboxes filter-checkboxes">
                    {renderDuration()}
                    {courseDurationList?.length > 4 && (
                        <button 
                            type="button"
                            style={{
                                border: 'none',
                                background: 'none', 
                                color: '#6c63ff',
                                fontSize: '0.9rem',
                                padding: '4px 0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowMoreDuration(!showMoreDuration)}
                        >
                            {showMoreDuration ? 'Show Less' : 'Show More'}
                            <i className={`bi bi-chevron-${showMoreDuration ? 'up' : 'down'}`} />
                        </button>
                    )}
                </div>
                <h6 className="filter-title">Course Level</h6>
                <div className="checkboxes filter-checkboxes">
                    {renderCourseLevel()}
                    {courseLevelList?.length > 4 && (
                        <button 
                            type="button"
                            style={{
                                border: 'none',
                                background: 'none',
                                color: '#6c63ff',
                                fontSize: '0.9rem',
                                padding: '4px 0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowMoreCourseLevel(!showMoreCourseLevel)}
                        >
                            {showMoreCourseLevel ? 'Show Less' : 'Show More'}
                            <i className={`bi bi-chevron-${showMoreCourseLevel ? 'up' : 'down'}`} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CourseFilter;