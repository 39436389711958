import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateStudent } from '../../../@redux/dispatch/students/updateStudent';
import dayjs from 'dayjs';
import { getStudentDetails } from '../../../@redux/dispatch/students/getDetails';
import Loader from '../../Loader';
import UploadDocument from './UploadDocument';
const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(timezone);

function PersonalDetailsForm({ setActiveTab }) {
    const [showFileUpload, setShowFileUpload] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState('')
    const [isUpdated, setIsUpdated] = useState(false); // Track if update button is clicked
    const autoDocument = useSelector((state) => state.uploadDocuments?.dataExtracted)

    console.log("Automatic data extraction is", autoDocument)

    const dispatch = useDispatch();
    const personalData = useSelector((state) => state.studentsDetails?.data)
    console.log("Personal details are", personalData)
    const [personalDetails, setPersonalDetails] = useState({})


    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setPersonalDetails({
            ...personalDetails,
            [name]: value
        })
    }
    const isFormComplete = () => {
        return (
            (autoDocument?.First_Name || personalData?.First_Name) &&
            (autoDocument?.Last_Name || personalData?.Last_Name) &&
            (autoDocument?.Date_of_Birth || personalDetails?.Date_of_Birth) &&
            (autoDocument?.Nationality || personalDetails?.Nationality) &&
            personalData?.Email &&
            personalData?.Phone
        );
    };

    useEffect(() => {
        setPersonalDetails({
            Nationality: (personalData?.Nationality) || '',
            Date_of_Birth: (personalData?.Date_of_Birth) || ''
        })
    }, [personalData])

    const handleUpdate = (event) => {
        event.preventDefault();
        dispatch(updateStudent(personalDetails));
        // dispatch(getStudentDetails())
        setIsUpdated(true); // After updating, allow "Next" button to appear
    }
    useEffect(() => {
        console.log(personalData, 1102);
    }, [personalData])

    return (
        <>
            {/* <Loader /> */}
            <div className="tab-pane fade show active"
                id="pills-personal"
                role="tabpanel"
                aria-labelledby="pills-personal-tab">
                <form onSubmit={handleUpdate}>
                    <UploadDocument />
                    <div className="form-separator" />
                    <div className="form-area">
                        <div className="form-head">
                            Your Personal Details{" "}
                            <span>We will use your personal details while processing your applications</span>
                        </div>
                        <div className="form-subhead">

                        </div>
                        <div className="row clearfix">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>First name</label>
                                <input
                                    type="text"
                                    id='firstName'
                                    name="firstName"
                                    className="form-control"
                                    placeholder="First name"
                                    value={autoDocument?.First_Name || personalData?.First_Name}
                                    disabled
                                />
                                {/*  */}

                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Last name</label>
                                <input
                                    type="text"
                                    id='lastName'
                                    name="lastName"
                                    className="form-control"
                                    placeholder="Last name"
                                    value={autoDocument?.Last_Name || personalData?.Last_Name}
                                    disabled
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2' htmlFor='dateBirth'>Date of birth</label>
                                {/* <CustomDatePicker onChange={(val) => alert(val)} value={dayjs(personalDetails?.Date_of_Birth)} className="w-100 form-control" name="dateBirth" id="dateBirth" placeholder="date of birth" required /> */}
                                {/* <CustomDatePicker onChange={(val) => setPersonalDetails({ ...personalDetails, Date_of_Birth: dayjs.utc(val).tz('Asia/Kolkata') })} value={dayjs(personalDetails?.Date_of_Birth)} className="w-100 form-control" name="dateBirth" id="dateBirth" placeholder="date of birth" required /> */}
                                <input
                                    type="date"
                                    id='Date_of_Birth'
                                    name="Date_of_Birth"
                                    className="form-control"
                                    placeholder="Date_of_Birth"
                                    value={autoDocument?.Date_of_Birth || personalDetails?.Date_of_Birth}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Nationality</label>
                                <input
                                    type="text"
                                    id='Nationality'
                                    name="Nationality"
                                    className="form-control"
                                    placeholder="Nationality"
                                    value={autoDocument?.Nationality || personalDetails?.Nationality}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Email</label>
                                <input
                                    type="email"
                                    id='email'
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    value={personalData?.Email}
                                    disabled
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label mb-2'>Mobile number</label>
                                <div className="field-phone">
                                    <span className="country-code">
                                        <img
                                            src="images/icons/indian-flag.svg"
                                            className="img-fluid mCS_img_loaded"
                                            alt="indian flag goes here"
                                        />
                                        {/* <select
                                            name="ccode"
                                            id="ccode"

                                            disabled
                                        >
                                            <option selected value={personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}>{personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}</option>
                                        </select> */}
                                        <p className='m-auto'>{personalData?.Phone?.slice(0, (personalData?.Phone?.length || 0) - 10)}</p>

                                    </span>
                                    <input
                                        type="tel"
                                        name="phone"
                                        id="phone"
                                        className="form-control"
                                        placeholder="Mobile Number"
                                        value={personalData?.Phone?.slice((personalData?.Phone?.length || 0) - 10)}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='container mt-5'>
                            <div className='row justify-content-end clearfix'>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                    {/* <button className="btn btn-theme me-1" onClick={() => { setActiveTab("address") }}>Next</button>
                                    <button className="btn btn-theme"  type='submit' >Update profile</button>  */}


                                    {(isFormComplete() || isUpdated) && (
                                        <button className="btn btn-theme me-1" onClick={() => setActiveTab("address")}>
                                            Next
                                        </button>
                                    )}
                                    <button className="btn btn-theme" type='submit' onClick={handleUpdate}>
                                        Update profile
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>

                </form>

            </div>
        </>


    )
}

export default PersonalDetailsForm