import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    isLogin: false,
    username: "",
    baseUrl: "https://api-sandbox.edbucket.com:5000/api/",
    loginMessage: "",
    error: false,
    isSessionValid: false,
    sessionMessage: "",
    profile_id: 'student',
    userData: null,
    activeTab: 'student',
    loader: false,
    userAttributes: [],
    newPasswordRequired: false,
    sessionData: null
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        loginStart: (state) => {
            return { ...state, loginMessage: "Logging in...", error: false };
        },
        loginSuccess: (state, action) => {
            return {
                ...state,
                loginMessage: 'Login Successful!',
                isLogin: true,
                username: action.payload.username
            };
        },
        newPasswordRequired: (state) => {
            return { ...state, newPasswordRequired: true };
        },
        getSession :(state,action)=>{
            return {...state, sessionData: action.payload.session}
        },
        loginFailure: (state, action) => {
            return {
                ...state,
                loginMessage: action.payload.errorMessage,
                error: true
            };
        },
        sessionStart: (state) => {
            return { ...state, isSessionValid: false, sessionMessage: "session started ...", error: false };
        },
        sessionSuccess: (state, action) => {
            const { profile, userData, userAttributes } = action.payload;
            console.log("action payload", action.payload);
            console.log("session response in action of config", action.payload)
            return { ...state, isSessionValid: true, sessionMessage: "Session added successfully", error: false, userData: userData, userAttributes: userAttributes };
        },
        setActiveTab: (state, action) => {
            return { ...state, activeTab: action.payload, profile_id: action.payload }
        },
        openLoader: (state) => {
            state.loader = true
        },
        closeLoader: (state) => {
            state.loader = false
        },
        resetConfig: (state) => {
            return { ...state, activeTab: 'student', profile_id: 'student' }
        }
    },
});


export const { loginStart, loginSuccess, loginFailure, sessionStart, sessionSuccess, setActiveTab, openLoader, closeLoader, resetConfig, newPasswordRequired, getSession } = configSlice.actions;




export default configSlice.reducer;
