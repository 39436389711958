import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { updateStudent } from '../../../@redux/dispatch/students/updateStudent';
import Loader from '../../Loader';

function AddressDetailsForm({ setActiveTab }) {

    const dispatch = useDispatch();
    const addressData = useSelector((state) => state.studentsDetails?.data)
    console.log("address details are", addressData)
    const personalData = useSelector((state) => state.studentsDetails.data)
    const autoDocument = useSelector((state) => state.uploadDocuments?.dataExtracted)
    const [isUpdated, setIsUpdated] = useState(false); 
    console.log("Automatic data extraction is", autoDocument)
    const [addressDetails, setAddressDetails] = useState({})

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setAddressDetails({
            ...addressDetails,
            [name]: value
        })
    }

    useEffect(() => {
        // 
        // console.log(addressData, 90)
        setAddressDetails({
            Date_of_Birth: (personalData?.Date_of_Birth) || '',
            Nationality: (personalData?.Nationality) || '',
            Street_Address: (addressData?.Street_Address) || '',
            Street_Address_Line_2: (addressData?.Street_Address_Line_2) || '',
            Mailing_City: (addressData?.Mailing_City) || '',
            Mailing_State: (addressData?.Mailing_State) || '',
            Mailing_Zip: (addressData?.Mailing_Zip) || '',
            Mailing_Country: (addressData?.Mailing_Country) || '',
        })
    }, [addressData])

    // const handleUpdate = (event) => { //previous logic
    //     event.preventDefault();
    //     dispatch(updateStudent(addressDetails));
    //     setIsUpdated(true);
    // }

    const handleUpdate = async (event) => {
        event.preventDefault();
        try {
            await dispatch(updateStudent(addressDetails));
            setIsUpdated(true); // Set updated state only after successful update
        } catch (error) {
            console.error("Update failed", error);
        }
    };

    const isFormComplete = () => {
        return (
            (autoDocument?.Street_Address || addressDetails?.Street_Address) &&
            (autoDocument?.Mailing_City || addressDetails?.Mailing_City) &&
            (autoDocument?.Mailing_State || addressDetails?.Mailing_State) &&
            (autoDocument?.Mailing_Zip || addressDetails?.Mailing_Zip) &&
            (autoDocument?.Mailing_Country || addressDetails?.Mailing_Country)
        );
    };

    return (

        <>
            {/* <Loader /> */}
            <div
                className="tab-pane fade show active"
                id="pills-address"
                role="tabpanel"
                aria-labelledby="pills-address-tab"
            >
                <form onSubmit={handleUpdate}>

                    <div className="form-area">
                        <div className="form-head">
                            Your Address Details{" "}
                            <span>We will use your address details while processing your applications</span>
                        </div>
                        <div className="form-subhead">
                            <hr />
                        </div>
                        <div className="row clearfix">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Street Address Line 1</label>
                                <input
                                    type="text"
                                    id='Street_Address'
                                    name="Street_Address"
                                    className="form-control"
                                    placeholder="Street Address Line 1"
                                    value={autoDocument?.Street_Address || addressDetails?.Street_Address}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Street Address Line 2</label>
                                <input
                                    type="text"
                                    id='Street_Address_Line_2'
                                    name="Street_Address_Line_2"
                                    className="form-control"
                                    placeholder="Street Address Line 2"
                                    value={autoDocument?.Street_Address_Line_2 || addressDetails?.Street_Address_Line_2}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>City</label>
                                <input
                                    type="text"
                                    name="Mailing_City"
                                    id="Mailing_City"
                                    className="form-control"
                                    placeholder="City"
                                    value={autoDocument?.Mailing_City || addressDetails?.Mailing_City}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>State/Province</label>
                                <input
                                    type="text"
                                    id='Mailing_State'
                                    name="Mailing_State"
                                    className="form-control"
                                    placeholder="State"
                                    value={autoDocument?.Mailing_State || addressDetails?.Mailing_State}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Postal/Zip Code</label>
                                <input
                                    type="Number"
                                    id='Mailing_Zip'
                                    name="Mailing_Zip"
                                    className="form-control"
                                    placeholder="Postal Code"
                                    value={autoDocument?.Mailing_Zip || addressDetails?.Mailing_Zip}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Country</label>
                                <input
                                    type='text'
                                    name='Mailing_Country'
                                    id='Mailing_Country'
                                    className='form-control'
                                    placeholder='Country'
                                    value={autoDocument?.Mailing_Country || addressDetails?.Mailing_Country}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className='container'>
                            <div className='row justify-content-end clearfix'>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                    <button className="btn btn-theme me-1" onClick={() => { setActiveTab("personal") }}>Previous</button>
                                    {(isFormComplete() || isUpdated) && (
                                    <button className="btn btn-theme me-1" onClick={() => setActiveTab("references")}>
                                        Next
                                    </button>
                                )}
                                    <button className="btn btn-theme" type='submit'>Update profile</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        </>


    )
}

export default AddressDetailsForm