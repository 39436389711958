import axios from "axios";
import { getAgentCounsellorDetailsStart, getAgentCounsellorDetailsSuccess } from "../../actions/agents/GetAgentCounsellorDetails";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";

export const agentCounsellorDetailsId = (id) => async (dispatch, getState) => {
    console.log("id:", id);
    const baseUrl = getState().config.baseUrl
    dispatch(getAgentCounsellorDetailsStart());
    dispatch(openLoader());
    try {
        const config = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'include'
            },
            withCredentials: true,
            redirect: 'follow'
        };

        const response = await axios.get(`${apiUrl}/api/counsellor/details/${id}`, config);
        dispatch(getAgentCounsellorDetailsSuccess(response.data));
    } catch (error) {
        console.error(error);
    }
    finally {
        dispatch(closeLoader())
    }
};
