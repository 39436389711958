import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import apiUrl from '../ApiUrl';

const initialState = {
    courseOptions: [],
    base_url: "",
    coursesMessage: "",
    error: false,
    loading: true
}

export const courseSearch = createSlice({
    name: 'courseSearch',
    initialState,
    reducers: {
        coursesSearchStart: (state) => {
            return { ...state, coursesMessage: "Search is going to start...", error: false, loading: true, courseOptions: [] };
        },
        coursesSearchSuccess: (state, action) => {
            // console.log(action.payload.courses);
            return {
                ...state,
                coursesMessage: "Search Successful",
                courseOptions: action.payload.courses,
                loading: false
            };
        },
        coursesSearchFailure: (state, action) => {
            return {
                ...state,
                coursesMessage: action.payload.errorMessage,
                error: true,
                loading: false
            };
        },
    },
});

export const { coursesSearchStart, coursesSearchSuccess, coursesSearchFailure } = courseSearch.actions;

// Thunk action to handle login
export const getCourseOptions = (searchCriteria) => async (dispatch, getState) => {
    dispatch(coursesSearchStart());
    const baseUrl = getState().config.baseUrl

    try {
        const response = await axios.post(

            `${apiUrl}/api/proxy`,
            {
                method: "get",
                endpoint: "/Course/search",
                payload: {
                    criteria: `(Name:starts_with:${searchCriteria})`,
                    fields: "Name,University,Duration,Course_Level,Type,Department,Country"
                }
            }
        );

        if (response.data.data) {
            const options = response.data.data?.map((course) => (
                {
                    label: course.Name + " (" + (course.University?.name || "Not Available") + ")",
                    // label: course.Name,
                    value: course.id,
                    level: course.Course_Level,
                    type: course.Type,
                    // department: course.Department,
                    duration: course.Duration,
                    country: course.Country
                }
               
            ))
            console.log("COurse search data", response.data.data);
            dispatch(coursesSearchSuccess({ courses: options }));
            console.log("COurse search data", options);
        } else {
            dispatch(coursesSearchFailure({ errorMessage: 'No courses found.' }));
        }
    } catch (error) {
        dispatch(coursesSearchFailure({ errorMessage: 'An error occurred while searching for courses. Please try again.' }));

    }
};



export default courseSearch.reducer;
