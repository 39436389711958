import axios from "axios";
import { agentAddStudentStart, agentAddStudentSuccess } from "../../../actions/agents/addStudent";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const agentAddStudent = (body, navigate) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    console.log("body for agent student creation", body);
    dispatch(agentAddStudentStart());
    dispatch(openLoader());
    try {
        const response = await axios.post(
            `${apiUrl}/api/agent/create/student`,
            body
        );
        
        if (response?.data) {
            await dispatch(agentAddStudentSuccess(response.data));
            
            // Call AdminCreateStudent after successful student creation
            const adminCreateData = {
                First_Name: body.First_Name,
                Last_Name: body.Last_Name, 
                Email: body.Email
            };
            
            try {
                await dispatch(AdminCreateStudent(adminCreateData));
                if (navigate) {
                    navigate('/student-list');
                }
            } catch (adminError) {
                console.error("Error creating admin user:", adminError);
            }
        }

    } catch (error) {
        console.error("Error creating student:", error);
    } finally {
        dispatch(closeLoader());
    }
};

export const AdminCreateStudent = (body) => async (dispatch) => {
    console.log("Creating admin user for student:", body);

    const headers = {
        'Content-Type': 'application/json'
    };

    const data = {
        "First_Name": body.First_Name,
        "Last_Name": body.Last_Name,
        "Email": body.Email,
        "Phone": body.Phone,
        "Date_of_Birth": body.Date_of_Birth,
        "Nationality": body.Nationality,
        "ISD_Code": body.ISD_Code,
        "Street_Address": body.Street_Address,
        "Street_Address_Line_2": body.Street_Address_Line_2,
        "City": body.City,
        "Mailing_State": body.Mailing_State,
        "Mailing_Zip": body.Mailing_Zip,
        "Please_upload_photo_identification_of_yourself": "",
        "profile": "student"
    };

    try {
        const response = await axios.post(
            `${apiUrl}/api/cognito/admin/create/user`,
            data,
            {
                headers: headers,
                redirect: 'follow',
                withCredentials: true // This will automatically handle cookies
            }
        );
        console.log("Admin user created successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Failed to create admin user:", error);
        throw error;
    }
}


