import axios from "axios";
import { getAgentsStart, getAgentsSuccess } from "../../actions/agents/GetAgents";
import { getAgentStudentsStart, getAgentStudentsSuccess } from "../../actions/agents/GetAgentStudents";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";
import { getAgentCounsellorsStart, getAgentCounsellorsSuccess } from "../../actions/agents/GetAgentCounsellors";

export const getAgentStudents = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getAgentStudentsStart());
    dispatch(openLoader())
    try {
        const response = await axios.get(
            `${apiUrl}/api/agent/student-list`
        );
        dispatch(getAgentStudentsSuccess(response.data.data))
        // navigate('/student-list')
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }

};

export const getAgentCounsellors = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(getAgentCounsellorsStart());
    dispatch(openLoader())
    try {
        const response = await axios.get(
            `${apiUrl}/api/counsellor/list`
        );
        dispatch(getAgentCounsellorsSuccess(response.data.data))
        // navigate('/student-list')
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }

};



