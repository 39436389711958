import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import ModalSchema from './ModalSchema';
import { BiLogIn } from 'react-icons/bi';
import { BeatLoader } from 'react-spinners';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { createNewPassword, forgotPassword, login, resetPassword, sessionCheck } from '../../@redux/dispatch/config';
import apiUrl from '../../@redux/ApiUrl';
import { generateOTPUnconfirmed } from '../../@redux/dispatch/unconfirmedUserDispatch';
import toastr from "toastr";
import RegisterModal from './RegisterModal';

function LoginModal({ userProfile }) {
    console.log("profile inside login modal is :", userProfile)
    const profile_id = useSelector((state) => (state?.config?.profile_id))
    // const sessionData = useSelector((state) => (state?.config?.sessionData))
    console.log("profile_id inside login modal is :", profile_id)
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    const [loginmessage, setLoginMessage] = useState("");
    const [resetPassMsg, setResetPassMsg] = useState("");
    const [forgotPassMsg, setForgotPassMsg] = useState("");
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);
    const [isResetModalOpen, setIsResetModalOpen] = useState(false);
    const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);
    const [isNewPasswordModalOpen, setIsNewPasswordModalOpen] = useState(false);
    const [sessionData, setSessionData] = useState(null);
    const [verifyMessage, setVerifyMessage] = useState("");
    const [verifyFormData, setVerifyFormData] = useState({ otp: '' });
    const [formData1, setFormData1] = useState({
        email: '',
        password: '',
        profile: profile_id
    });
    const [forgotPassEmail, setForgotPassEmail] = useState('')
    const [resetPassData, setResetPassData] = useState({
        code: '',
        newPassword: ''
    })
    const [newPasswordData, setNewPasswordData] = useState({
        newPassword: ''
    });
    console.log(loginmessage)

    // Handle clicking outside modal
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (e.target.classList.contains('modal')) {
                setIsModalOpen(false);
                setIsForgotModalOpen(false);
                setIsResetModalOpen(false);
                setIsVerifyModalOpen(false);
                setIsNewPasswordModalOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, []);

    const isPasswordValid = (password) => {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@]).{8,}$/;
        return passwordPattern.test(password);
    };

    const handleInputChange1 = (event) => {
        const { name, value } = event.target;
        setFormData1({
            ...formData1,
            [name]: value,
            profile: profile_id
        });
    };

    const resetPassInput = (event) => {
        const { name, value } = event.target;
        setResetPassData({
            ...resetPassData,
            [name]: value
        })
    }

    const handleNewPasswordInput = (event) => {
        const { name, value } = event.target;
        setNewPasswordData({
            ...newPasswordData,
            [name]: value
        });
    };

    const handleNewPasswordSubmit = (event) => {
        event.preventDefault();
        const passwordValid = isPasswordValid(newPasswordData.newPassword);

        if (!passwordValid) {
            setLoginMessage('Password must contain one uppercase letter, one lowercase letter, and the @ symbol.');
        } else {
            console.log("Email:", formData1.email);
            console.log("New Password:", newPasswordData.newPassword);
            console.log("Session:", sessionData);
            console.log("Profile...42223:", userProfile);
            
            dispatch(createNewPassword({
                email: formData1.email,
                new_password: newPasswordData.newPassword,
                profile: userProfile,
                session: sessionData
            }, navigate, setLoginMessage));
            setIsNewPasswordModalOpen(false);
        }
    };

    console.log("formData :", formData1)
    axios.defaults.withCredentials = true
    // useEffect(() => {
    //     dispatch(sessionCheck(navigate))
    // }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await dispatch(login(formData1, navigate, setLoginMessage));
        if (response?.isNewPasswordRequired || response?.isForceChangePassword || response?.Session) {
            setIsNewPasswordModalOpen(true);
            setSessionData(response?.Session);
        }
    };

    const forgotPassSubmit = (event) => {
        event.preventDefault();
        console.log("here i am guys")
        console.log("email id is here ", forgotPassEmail)
        dispatch(forgotPassword({ "email": forgotPassEmail }, setForgotPassMsg, setIsForgotModalOpen, setIsResetModalOpen))
    }

    const handleResetPass = (event) => {
        event.preventDefault();
        const passwordValid = isPasswordValid(resetPassData.newPassword);

        if (!passwordValid) {
            console.log(resetPassData.newPassword)
            setResetPassMsg('Password must contain one uppercase letter, one lowercase letter, and the @ symbol.');
        }
        else {
            dispatch(resetPassword(resetPassData, setResetPassMsg, setIsResetModalOpen, setIsModalOpen))
        }

    }


    const varifyHandleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setVerifyFormData({
            ...verifyFormData,
            [name]: value,
        });
    };

    const verifyhandleSubmit = async (event) => {
        event.preventDefault();
        if (!verifyFormData.otp) {
            setVerifyMessage('please enter the OTP code');
        } else {
            try {
                const emailToSend = formData1.email === '' ? forgotPassEmail : formData1.email;
                const response = await axios.post(`${apiUrl}/api/cognito/verify/Otp`, { email: emailToSend, ...verifyFormData });
                console.log('API Response:', response.data);
                console.log("status", response.status);
                toastr.success("Email is verified. Now proceed further");

                if (!response.data.errorCode) {
                    setIsVerifyModalOpen(false);
                    console.log("OTP Confirmed!");
                    setVerifyMessage('OTP Confirmed!');
                }
                else {
                    // Display the error message if there's an error
                    setVerifyMessage(response.data.msg.split(":")[1]);
                }
            } catch (error) {
                console.error('API Error:', error);
                setVerifyMessage('An error occurred while logging in. Please try again.');
            }

            // verifyOTP(verifyFormData, setIsVerifyModalOpen, setVerifyMessage)
        }

    };

    // new password content
    const newPasswordContent = <div>
        <div className="modal-header border border-0">
            <h2 className="modal-title">
                Set New Password
            </h2>
            <button
                type="button"
                className="btn-close"
                onClick={() => setIsNewPasswordModalOpen(false)}
            />
        </div>
        {loginmessage && (
            <div className="alert alert-success">
                Enter your new password
            </div>
        )}
        <form onSubmit={handleNewPasswordSubmit}>
            <div className="modal-body p-0">
                <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    className="form-control"
                    placeholder="New Password"
                    value={newPasswordData.newPassword}
                    onChange={handleNewPasswordInput}
                    required
                />
            </div>
            <div className="modal-footer border border-0">
                <button type="submit" className="btn btn-gradient">
                    Submit
                </button>
            </div>
        </form>
    </div>

    // forgot password content
    const forgotContent = <div>
        <div className="modal-header border border-0 " >
            <h2 className="modal-title" >
                Reset password
            </h2>
            <button
                type="button"
                className="btn-close"
                onClick={() => setIsForgotModalOpen(false)}
            />
        </div>
        {(forgotPassMsg) && (
            <div className='alert alert-danger'>
                {forgotPassMsg}
            </div>
        )}
        <form onSubmit={forgotPassSubmit} method="post" id="verifyForm" action="" role="form">
            <div className="modal-body p-0">
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Enter Your Email"
                    value={forgotPassEmail}
                    onChange={(e) => setForgotPassEmail(e.target.value)}
                    required

                />
            </div>
            <div className="modal-footer border border-0">
                <button
                    type="button"
                    className="border border-0 bg-transparent me-3 "
                    onClick={() => { setIsModalOpen(true); setIsForgotModalOpen(false) }}
                >
                    <BiLogIn size={18} className='text-warning' /> <small>Sign in</small>
                </button>
                {
                    forgotPassMsg === 'User is unconfirmed' ?
                        <div className='' >
                            <button type='button' id="resend" name="resend" onClick={(e) => {
                                console.log("formdata inside generate otp", formData1)
                                console.log("reset email inside generate otp", forgotPassEmail)
                                // forgotPassEmail
                                const emailToSend = formData1.email === '' ? forgotPassEmail : formData1.email;
                                setVerifyFormData({ otp: '' });
                                setVerifyMessage('')
                                dispatch(generateOTPUnconfirmed({ email: emailToSend }, setIsModalOpen, setIsVerifyModalOpen, setIsForgotModalOpen))
                            }} className="btn btn-gradient ">Generate OTP</button>
                        </div>
                        :
                        <button type="submit" className="btn btn-gradient" >
                            Submit
                        </button>
                }


            </div>
        </form>

    </div>

    // reset password content
    const resetPasswordContent = <div>
        <div className="modal-header border border-0">
            <h2 className="modal-title" >
                Reset password
            </h2>
            <button
                type="button"
                className="btn-close"
                onClick={() => { setIsResetModalOpen(false) }}
            />
        </div>
        {resetPassMsg && (
            <div className={`alert ${resetPassMsg.startsWith('Successfully Received') ? 'alert-success' : 'alert-danger'}`}>
                {resetPassMsg}
            </div>
        )}
        <form onSubmit={handleResetPass} >
            <div className="modal-body p-0">
                <input
                    type="number"
                    name="code"
                    id="code"
                    className="form-control"
                    placeholder="OTP from email"
                    value={resetPassData.code}
                    onChange={resetPassInput}
                    required
                />

                <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    className="form-control"
                    placeholder="New Password"
                    value={resetPassData.newPassword}
                    onChange={resetPassInput}
                    required
                />
            </div>

            <div className="modal-footer border border-0">
                <button
                    type="button"
                    className="border border-0 bg-transparent me-3 "
                    onClick={() => { setIsModalOpen(true); setIsResetModalOpen(false) }}
                >
                    <BiLogIn size={18} className='text-warning' /> <small>Sign in</small>
                </button>
                <button type="submit" className="btn btn-gradient"
                >
                    Submit
                </button>
            </div>
        </form>

    </div>


    // login content
    const content = <div >
        <button
            type="button"
            className="btn-close"
            onClick={() => setIsModalOpen(false)}
        />
        <h1>Login to Edbucket</h1>
        {loginmessage && (
            <div className={`alert ${loginmessage.startsWith('Login Successful') ? 'alert-success' : 'alert-danger'}`}>
                {loginmessage}
            </div>
        )}
        <form onSubmit={handleSubmit} method="post" id="loginform" role="form">
            <div className="form-group mb-3">
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email"
                    required=""
                    value={formData1.email}
                    onChange={handleInputChange1}
                />
            </div>
            <div className="form-group mb-3">
                <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    required=""
                    value={formData1.password}
                    onChange={handleInputChange1}
                />
            </div>
            <div className='text-end'>
                <Link onClick={() => { setIsForgotModalOpen(!isForgotModalOpen); setIsModalOpen(false); setForgotPassEmail(''); setForgotPassMsg('') }}>Forgot Password</Link>
            </div>

            {
                (loginmessage === " User is not confirmed." ? (
                    <div className='text-center' >
                        <button type='button' id="resend" name="resend" onClick={(e) => {
                            console.log("formdata inside generate otp", formData1)
                            console.log("reset email inside generate otp", forgotPassEmail)
                            const emailToSend = formData1.email === '' ? forgotPassEmail : formData1.email;
                            setVerifyFormData({ otp: '' });
                            setVerifyMessage('')
                            dispatch(generateOTPUnconfirmed({ email: emailToSend }, setIsModalOpen, setIsVerifyModalOpen, setIsForgotModalOpen))
                        }} className="btn btn-gradient mt-32 w-50">Generate OTP</button>
                    </div>
                ) : (
                    <div className='text-center'>
                        {/* <input
                    type="submit"
                    id="submit"
                    name="submit"
                    defaultValue="Login"
                    className="btn btn-gradient mt-32 w-50"
                /> */}
                        <button type='submit' id="submit" name="submit" className="btn btn-gradient mt-32 w-50">Submit</button>
                    </div>

                ))


            }

        </form>
        <div className="separator">
            <span>or continue with</span>
        </div>
        <ul className="social-login">
            <li>
                <a href="#" className="temp">
                    <img src="images/google.png" />
                </a>
            </li>
            <li>
                <a href="#" className="temp">
                    <img src="images/facebook.png" />
                </a>
            </li>
            <li>
                <a href="#" className="temp">
                    <img src="images/twitter.png" />
                </a>
            </li>
        </ul>
        <p className="signup-option">
            Don't have an account yet?{" "}
            {/* <button className='border-0 bg-transparent text-primary' onClick={() => { setIsModalOpen(false); }}>
                Register
            </button> */}
            <RegisterModal location={'Inside login'} setLoginModal={isModalOpen} />
        </p>
    </div>

    // verify unconfirmed user content
    const verifyContent = <div>
        <button
            type="button"
            className="btn-close"
            onClick={() => setIsVerifyModalOpen(false)}

        />
        <h1 className='text-center'>Get Started</h1>
        <p>A  OTP has been sent to </p>
        {verifyMessage && (
            <div className={`alert ${verifyMessage.startsWith('OTP Confirmed!') || verifyMessage.startsWith('Successfully Received Otp') ? 'alert-success' : 'alert-danger'}`}>
                {verifyMessage}
            </div>
        )}

        <form method="post" id="verifyForm" onSubmit={verifyhandleSubmit} action="" role="form">
            <div className="form-group mb-3">
                <input
                    type="text"
                    name="otp"
                    id="otp"
                    className="form-control"
                    placeholder="OTP"
                    required=""
                    value={verifyFormData.otp}
                    onChange={varifyHandleInputChange}
                />

            </div>
            <div className='text-center'>
                <input
                    type="submit"
                    id="otp"
                    name="otp"
                    defaultValue="Email - OTP"
                    className="btn btn-purple mt-32 w-50"
                />
            </div>

        </form>
    </div>

    return (
        <>

            {/* <Loader /> */}
            <ModalSchema content={content} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            <Link
                className="nav-link fw-bold"
                href="#"
                onClick={() => { setIsModalOpen(!isModalOpen); setFormData1({ email: '', password: '', profile: profile_id }); setLoginMessage('') }}
            >
                Login
            </Link>
            <ModalSchema content={forgotContent} isOpen={isForgotModalOpen} setIsOpen={setIsForgotModalOpen} />
            <ModalSchema content={resetPasswordContent} isOpen={isResetModalOpen} setIsOpen={setIsResetModalOpen} />
            <ModalSchema content={verifyContent} isOpen={isVerifyModalOpen} setIsOpen={setIsVerifyModalOpen} />
            <ModalSchema content={newPasswordContent} isOpen={isNewPasswordModalOpen} setIsOpen={setIsNewPasswordModalOpen} />

        </>

    )
}

export default LoginModal