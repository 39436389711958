import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    agentCounsellorMessage: "",
    error: false,
    counsellorDetails: []
}

export const getAgentCounsellorDetailsSlice = createSlice({
    name: 'getAgentCounsellorDetails',
    initialState,
    reducers: {
        getAgentCounsellorDetailsStart: (state) => {
            return { ...state, agentCounsellorMessage: "Getting agent's counsellor details started ...", error: false, counsellorDetails: [] };
        },
        getAgentCounsellorDetailsSuccess: (state, action) => {
            return { ...state, agentCounsellorMessage: "Agent's counsellor details fetched successfully", error: false, counsellorDetails: action.payload };
        }
    }
})

export const { getAgentCounsellorDetailsStart, getAgentCounsellorDetailsSuccess } = getAgentCounsellorDetailsSlice.actions;

export default getAgentCounsellorDetailsSlice.reducer;