import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LeftSidebar from '../../components/LeftSidebar'
import Footer from '../../components/Footer'
import ToastContainer from '../../components/ToastContainer'
import Details from '../../universities.json'
import MobMenuHeader from '../../components/MobMenuHeader'
import UnivLeftSidebar from '../../components/UnivLeftSidebar'
import Sort from '../../components/Structures/SortBy/Sort'
import { LuArrowUpDown } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import { FaRegShareFromSquare } from 'react-icons/fa6'
import { AiOutlineHeart } from 'react-icons/ai'
import ModalSchema from '../../components/Modals/ModalSchema'
import MobFiltersModal from '../../components/Modals/MobFiltersModal'
import MobSortbyModal from '../../components/Modals/MobSortbyModal'
import Filters from '../../components/Structures/Filters/Filters'
import Breadcrump from '../../components/Structures/Breadcrump/Breadcrump'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import AddUniversitiesModal from '../../components/Modals/AddUniversitiesModal'
import UniversityListCounsellor from '../../components/UniversityListCounsellor'


function LoadingSkeleton() {
    return (
        <div>
            <div className='d-flex'>
                <div>
                    <Skeleton width={200} height={250} className='mb-4' />
                    <Skeleton width={200} height={70} className='mt-3' />
                </div>
                <div className='ms-4 mt-3'>
                    <Skeleton width={350} height={20} />
                    <Skeleton width={400} height={20} className='my-4 p-4' />
                    <Skeleton width={700} height={80} className='mt-5' />
                </div>
            </div>
            <div className='d-flex mt-5'>
                <div>
                    <Skeleton width={200} height={250} className='mb-4' />
                    <Skeleton width={200} height={70} className='mt-3' />
                </div>
                <div className='ms-4 mt-3'>
                    <Skeleton width={350} height={20} />
                    <Skeleton width={400} height={20} className='my-4 p-4' />
                    <Skeleton width={700} height={80} className='mt-5' />
                </div>
            </div>
        </div>
    )
}

function NoDataFound() {
    return (
        <div className='title text-center '>
            <h2 className='text-primary'>Oops! No universities are added yet. Add new university. 😊</h2>
        </div>
    )
}

function UniversityList({ searchData }) {
    const Universities = useSelector((state) => (state?.universityList?.universityList))

    return (
        Universities?.filter((university) => {
            return searchData.toLowerCase() === '' ?
                university : university?.universityResponse?.Name.toLowerCase().includes(searchData);
        })?.map((university, idx) => (
            <li key={idx}>
                <div>
                    <div className="course-box clearfix">
                        <div className="row clearfix">
                            <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="course-imgholder tablet desktop">
                                    <img
                                        src="images/dummy-image.png"
                                        alt="dummyImage"
                                    />
                                    <div className="university-logo">
                                        <img
                                            src="images/course-university.png"
                                            alt="UniveristyImage"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-8 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
                                <div className="course-details">
                                    <div className="row clearfix">
                                        <div className="col-12 col-md-8 col-lg-8 col-xl-9 col-xxl-9">
                                            <h2>{university?.universityResponse?.Name}</h2>
                                            <div className="info-area">
                                                <h3>
                                                    {university?.universityResponse?.Name}{", "}{university?.universityResponse?.Country}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 col-xl-3 col-xxl-3 text-end">
                                            <a href="">Visit Website</a>
                                        </div>
                                    </div>
                                    <p className="course-text content-desktop">
                                        <span>About the University</span>
                                        {university?.universityResponse?.University_Description}
                                        <span>English Requirement</span>
                                        {university?.universityResponse?.English_Requirement}
                                    </p>
                                    {/* <div className='d-flex justify-content-end '>
                                    <button className='btn btn-gradient'>Show Courses</button>
                                </div> */}
                                    <p className="course-text content-mobile">
                                        <span>About the University</span>
                                        {university?.universityResponse?.University_Description}
                                        <span>English Requirement</span>
                                        {university?.universityResponse?.English_Requirement}
                                    </p>
                                    <a
                                        href="#"
                                        className="btn-more readMore d-md-none d-lg-none d-xl-none d-xxl-none"
                                    >
                                        More Details
                                        <i className="bi bi-chevron-down" />
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="uni-course">
                        {/* <h4 className="uni-course-title">Courses</h4> */}
                        <table className="table table-borderless course-table w-100 responsive-tables">
                            <thead>
                                <tr>
                                    <th>Course Name</th>
                                    <th>Duration</th>
                                    <th>Level</th>
                                    <th ></th>
                                </tr>
                            </thead>

                            <tbody>
                                {Array.isArray(university?.courseListData) && university?.courseListData?.map((courseData, idx) => (
                                    <tr key={idx}>
                                        <td data-title="Course">
                                            {courseData?.Course_List?.name}
                                        </td>
                                        <td data-title="Duration">{courseData?.Duration}</td>
                                        <td data-title="Level">{courseData?.Course_Level}</td>
                                        <td className=''>
                                            <div className="action-tools ">
                                                <Link to="#" className="btn-circle">
                                                    <FaRegShareFromSquare size={16} />
                                                </Link>
                                                <Link to="#" className="btn-circle">
                                                    <AiOutlineHeart size={16} />
                                                </Link>
                                                <Link to={"/create-application?course=" + courseData?.Course_List?.name} className="btn btn-purple">
                                                    Apply
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </li>
        ))
    )
}

function Universities() {
    const [filterModalOpen, setFilterModalOpen] = useState(false)
    const Universities = useSelector((state) => (state?.universityList?.universityList))
    const CounUniversities = useSelector((state) => (state?.universityList?.counUniversityList))
 
    const [search, setSearch] = useState('')
    const userRole = useSelector((state) => (state.config?.profile_id))
    const loading = useSelector((state) => (state?.universityList?.loading))
    const [isModalOpen, setIsModalOpen] = useState(false);

    console.log("Universities are", Universities)

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Universities', path: '/universities' }
    ]

    const openModal = (e) => {
        e.preventDefault()
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleClearSearch = () => {
        setSearch('');
        // Clear the input field value directly
        const searchInput = document.getElementById('search');
        if (searchInput) {
            searchInput.value = '';
        }
    };

    return (
        <>
            <div className="col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10">
                <div className="course-grid">
                    <div className="desktop">
                        <div className="fixed-header sticky-top desktop">
                            <Breadcrump items={breadcrumps} />

                            {/* <Breadcrumb data={{ main: "Home", subdivision: "All Universities", backurl: "/dashboard" }} /> */}
                            <div className="row clearfix">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-2 col-xxl-2">
                                    <h5 className="course-title-filter">Universities</h5>
                                    {
                                        userRole !== 'counsellor' ?
                                            <div className="filter-results-filter">{Universities?.length} Results</div>
                                            :
                                            <div className="filter-results-filter">{CounUniversities?.length} Results</div>
                                    }

                                </div>
                                <div className="col-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10 ">
                                    <div className="row align-items-center clearfix ms-5">
                                        <div className="col-6 text-center">
                                            {(userRole === "counsellor" || userRole === "agent") && (
                                                <>
                                                    <button
                                                        className="btn btn-purple"

                                                        onClick={openModal}
                                                    >
                                                        Add Universities
                                                    </button>
                                                    <AddUniversitiesModal isOpen={isModalOpen} closeModal={closeModal} />
                                                </>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <div className="search-bar position-relative">
                                                <input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    className="search-area"
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    placeholder="Search by university name "
                                                />
                                                {search && (
                                                    <button 
                                                        className="clear-btn position-absolute end-0 top-50 translate-middle-y me-2 border-0 bg-transparent"
                                                        onClick={handleClearSearch}
                                                    >
                                                        ✕
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablet mobile">
                        <div className="row align-items-center clearfix">
                            <div className="col-8">
                                <h5 className="course-title-mobile">Universities</h5>
                            </div>
                            <div className="col-4 text-end">
                                <div className="filter-results"> Results</div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-2 clearfix">
                        <Filters userRole={userRole} />
                        <div className="col-12 col-lg-12 col-xl-10 col-xxl-10">
                            <ul className="universities-grid uniScroll" id="universityGrid">
                                {(userRole !== 'counsellor') ?
                                    <>
                                        {Universities?.length === 0 && <LoadingSkeleton />}
                                        {Universities?.length > 0 && <UniversityList searchData={search} />}
                                    </>

                                    :
                                    <>
                                        {CounUniversities?.length === 0 && <LoadingSkeleton />}
                                        {(!loading && CounUniversities?.length === undefined) && <NoDataFound />}
                                        {CounUniversities?.length > 0 && <UniversityListCounsellor searchData={search} />}
                                    </>

                                }

                            </ul>
                        </div>
                    </div>
                </div>
                <ul className="filter-box-sm">
                    <li>
                        <div id="sort">
                            <svg
                                width={21}
                                height={20}
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_1006_671)">
                                    <path
                                        d="M10.0238 11.9737L14.7857 17.1429L19.5476 11.9737M10.9762 6.93924L6.2143 1.90476M6.2143 1.90476L1.45239 6.93546M6.2143 1.90476V17.0082M14.7857 1.90476V17.0082"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1006_671">
                                        <rect
                                            width={20}
                                            height={20}
                                            fill="white"
                                            transform="translate(0.5)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                            Sort
                        </div>
                    </li>
                    <li>
                        <div id="filter">
                            <svg
                                width={15}
                                height={18}
                                viewBox="0 0 15 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <ellipse
                                    cx="10.8334"
                                    cy="4.83333"
                                    rx="3.33333"
                                    ry="3.33333"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <ellipse
                                    cx="4.16674"
                                    cy="13.1667"
                                    rx="3.33333"
                                    ry="3.33333"
                                    transform="rotate(180 4.16674 13.1667)"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M0.833328 4.83325L7.5 4.83325"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14.1668 13.1667L7.50016 13.1667"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            Filters
                        </div>
                    </li>
                </ul>
                <ul
                    className="filter-box-sm filter-apply"
                    style={{ display: "none" }}
                >
                    <li>
                        <input
                            type="reset"
                            name="reset"
                            id="reset"
                            defaultValue="Clear All"
                        />
                    </li>
                    <li>
                        <input
                            type="submit"
                            name="submit"
                            id="submit"
                            defaultValue="Apply"
                        />
                    </li>
                </ul>
                <ul className="bottom-menu">
                    <li>
                        <Link to="/dashboard">
                            <i className="bi bi-house-fill" />
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="#" id="search-icon">
                            <i className="bi bi-search" />
                            Search
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <i className="bi bi-person-fill" />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link
                            data-bs-toggle="offcanvas"
                            to="#mobileMenu"
                            role="button"
                            aria-controls="mobileMenuExample"
                        >
                            <i className="bi bi-list" />
                            Menu
                        </Link>
                    </li>
                </ul>


            </div>
            {/* <ToastContainer /> */}
            {/* Modal */}
            <ModalSchema contentClassName='p-0' content={<MobFiltersModal setFilterModalOpen={setFilterModalOpen} />} isOpen={filterModalOpen} setIsOpen={setFilterModalOpen} />
        </>

    )
}

export default Universities
