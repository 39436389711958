import axios from "axios";
import { closeLoader, getSession, loginStart, newPasswordRequired, openLoader, sessionStart, sessionSuccess } from "../../actions/config";
import apiUrl from "../../ApiUrl";
import toastr from "toastr";

export const sessionCheck = (navigate) => async (dispatch, getState) => {
    dispatch(loginStart());
    dispatch(sessionStart());
    dispatch(openLoader())
    const baseUrl = getState().config.baseUrl;
    try {
        const response = await axios.get(`${apiUrl}/api/session`);
        if (response && response.data.valid) {
            const userData = response.data.userData.data[0];
            const profile = userData.profile;
            const userAttributes = response.data.userData.data[0].userInfo.UserAttributes
            console.log("user atrributes are", userAttributes)

            // navigate('/dashboard')
            dispatch(sessionSuccess({ profile, userData, userAttributes }));
        }
        else {
            const isStudentPage = window.location.pathname === '/students';
            const isAgentPage = window.location.pathname === '/recruiters';
            const isCounsellorPage = window.location.pathname === '/councellors';
            if (!isStudentPage || !isAgentPage || !isCounsellorPage) {
                navigate('/students')
            }
            else {
                let isSecuredRoute = isComingFromSecuredRoute(window.location.href.split("/")[window.location.href.split("/").length - 1]);
                if (isSecuredRoute) {
                    toastr.error("Session expired");
                }
            }

            // navigate("/students")
        }
    } catch (error) {
        console.log('API Error:', error);
        // console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
}

function isComingFromSecuredRoute(currentRoute) {
    if (currentRoute === "dashboard" || currentRoute === "student-list" || currentRoute === "agents" || currentRoute === "view-agent"
        || currentRoute === "applications" || currentRoute === "courses" || currentRoute === "edpay" || currentRoute === "learn"
        || currentRoute === "prepare" || currentRoute === "profile" || currentRoute === "settings" || currentRoute === "universities"
        || currentRoute === "create-application" || currentRoute === "view-application" || currentRoute === "student-profile"
        || currentRoute === "new-application" || currentRoute === "add-student" || currentRoute === "view-student"
        || currentRoute === "view" || currentRoute === "view-student-application" || currentRoute === "courseOfferLetter"
        || currentRoute === "chatbot") {
        return true;
    }
    return false;
}

export const login = (body, navigate, setLoginMessage) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        console.log('Login details is here', body)
        const response = await axios.post(`${apiUrl}/api/login`, body, {
            withCredentials: true,
        });

        if (!response.data.errorCode) {
            console.log('API Response:', response.data);
            console.log("status", response.status);
            console.log("LoginResponse :", response)
            console.log("Login Successful");
            setLoginMessage('Login Successful!');
            toastr.success("Logged in successfully");
            
            // Check if response contains challenge for new password
            const isNewPasswordRequired = response.data?.response?.ChallengeName === "NEW_PASSWORD_REQUIRED";
            const isForceChangePassword = response.data?.userInfo?.UserStatus === "FORCE_CHANGE_PASSWORD";
            const Session = response.data?.response?.Session;
            
            if (isNewPasswordRequired || isForceChangePassword) {
                dispatch(newPasswordRequired());
                dispatch(getSession(response.data?.response?.Session))
                return {
                    isNewPasswordRequired,
                    isForceChangePassword,
                    Session
                };
            } else {
                navigate("/dashboard");
            }
        }
        else {
            console.log("errorLoginResponse :", response)
            setLoginMessage(response.data.msg.split(":")[1]);
        }
    } catch (error) {
        console.error('API Error:', error);
        setLoginMessage('An error occurred while logging in. Please try again.');
    }
    finally {
        dispatch(closeLoader())
    }
}

export const createNewPassword = (body, navigate, setLoginMessage) => async (dispatch, getState) => {
    try {
        dispatch(openLoader())
        const response = await axios.post(
            `${apiUrl}/api/cognito/response_auth_new_challenge`,
            {
                email: body.email,
                new_password: body.new_password,
                profile: body.profile,
                session: body.session
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }
        );

        if (response.data) {
            console.log("New password set successfully");
            setLoginMessage('Password updated successfully!');
            toastr.success("Password updated successfully");
            navigate("/dashboard");
        }

    } catch (error) {
        console.error('API Error:', error);
        setLoginMessage('An error occurred while setting new password. Please try again.');
    } finally {
        dispatch(closeLoader())
    }
}

export const register = (body, setSignupMessage, setIsModalOpen, setIsVerifyModalOpen) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        console.log("register details are here:-", body)
        dispatch(openLoader())
        // setIsModalOpen(false);
        // setIsVerifyModalOpen(true);
        const response = await axios.post(`${apiUrl}/api/signup`, body);
        console.log('API Response:', response.data);
        console.log("status", response.status);
        if (!response.data.errorCode) {
            console.log("Signup Successful");
            setSignupMessage('Signup Successful!');
            setIsModalOpen(false);
            setIsVerifyModalOpen(true);
        }
        else {
            setSignupMessage(response.data.msg.split(":")[1]);
        }
    } catch (error) {
        console.error('API Error:', error);
        setSignupMessage('An error occurred while logging in. Please try again.');
    }
    finally {
        dispatch(closeLoader())
    }
}

export const verifyOTP = (body, setIsVerifyModalOpen, setVerifyMessage) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {
        dispatch(openLoader())
        const response = await axios.post(`${apiUrl}/api/otp`, body);
        console.log('API Response:', response.data);
        console.log("status", response.status);
        if (!response.data.errorCode) {
            setIsVerifyModalOpen(false);
            console.log("OTP Confirmed!");
            setVerifyMessage('OTP Confirmed!');
        }
        else {
            // Display the error message if there's an error
            setVerifyMessage(response.data.msg.split(":")[1]);
        }
    } catch (error) {
        console.error('API Error:', error);
        setVerifyMessage('An error occurred while logging in. Please try again.');
    }
    finally {
        dispatch(closeLoader())
    }
}

export const resendOTP = (setVerifyMessage) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    try {

        const response = await axios.post(`${apiUrl}/api/otp/resend`);
        console.log('API Response:', response.data);
        console.log("status", response.status);
        if (!response.data.errorCode) {
            console.log("OTP Confirmed!");
            setVerifyMessage('Successfully Received Otp');
        }
        else {
            // Display the error message if there's an error
            setVerifyMessage(response.data.msg.split(":")[1]);
        }
    } catch (error) {
        console.error('API Error:', error);
        setVerifyMessage('An error occurred while logging in. Please try again.');
    }
}

export const forgotPassword = (body, setForgotPassMsg, setIsForgotModalOpen, setIsResetModalOpen) => async (dispatch) => {
    dispatch(openLoader())
    try {
        const response = await axios.post(`${apiUrl}/api/forgot/password`, body);
        if (response.status === 200) {
            if (!response.data.message) {
                console.log("Successfully Received ")
                setForgotPassMsg("Successfully Received");
                setIsForgotModalOpen(false)
                setIsResetModalOpen(true);
            }
            else {
                setForgotPassMsg("User is unconfirmed")
            }

        }
        else if (response.status === 400) {
            console.log("User not found");
            setForgotPassMsg("User not found");

        }
    }
    catch (error) {
        console.log("api error :-", error)
    }
    finally {
        dispatch(closeLoader())
    }

}

export const resetPassword = (body, setResetPassMsg, setIsResetModalOpen, setIsModalOpen) => async (dispatch) => {
    dispatch(openLoader())
    try {
        const response = await axios.post(`${apiUrl}/api/forgot/confirm/password`, body);
        if (response.data.ResponseMetadata) {
            console.log("Successfully Received ")
            setResetPassMsg("Successfully Received");
            setIsResetModalOpen(false);

            setIsModalOpen(true);

        }
        else if (response.data.message) {
            console.log("Either of your otp or password is wrong");
            setResetPassMsg("Either of your otp or password is wrong");

        }
    }
    catch (error) {
        console.log("api error :-", error)
    }
    finally {
        dispatch(closeLoader())
    }
}
