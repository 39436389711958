import React, { useEffect, useState } from 'react';
import StudentListAgent from '../../components/StudentListAgent';
import StudentListCounsellor from '../../components/StudentListCounsellor';
import { useSelector } from 'react-redux';
import CounsellorListAgent from '../../components/CounsellorListAgent';

function CounsellorList() {
    const userRole = useSelector((state) => (state?.config?.profile_id))




    return (
        <>
            {userRole === "agent" && (
                <CounsellorListAgent />
            )}
        </>

    )
}

export default CounsellorList