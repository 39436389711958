import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    counsellorMessage: "",
    error: false
}

export const updateAgentCounsellorDetailsSlice = createSlice({
    name: 'updateAgentCounsellorDetails',
    initialState,
    reducers: {
        updateAgentCounsellorDetailsStart: (state) => {
            return { ...state, counsellorMessage: "Updating counsellor details started ...", error: false };
        },
        updateAgentCounsellorDetailsSuccess: (state, action) => {
            return { ...state, counsellorMessage: "Counsellor details updated successfully", error: false };
        }
    }
})

export const { updateAgentCounsellorDetailsStart, updateAgentCounsellorDetailsSuccess } = updateAgentCounsellorDetailsSlice.actions;

export default updateAgentCounsellorDetailsSlice.reducer;