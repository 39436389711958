import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isUniversity: false,
    universityMessage: "",
    error: false,
    universityList: [],
    counUniversityList: [],
    countryUniversityList: [],
    loading: true
}

export const universityListSlice = createSlice({
    name: 'universityList',
    initialState,
    reducers: {
        universityListStart: (state) => {
            return { ...state, universityMessage: "Fetching university started...", error: false, loading: true };
        },
        universityListSuccess: (state, action) => {
            return { ...state, universityMessage: "Universities fetched successfully", error: false, isUniversity: true, universityList: action.payload, loading: false };
        },
        counUniversityListSuccess: (state, action) => {
            return { ...state, universityMessage: "Universities fetched successfully", error: false, isUniversity: true, counUniversityList: action.payload, universityList: [], loading: false };
        },
        countryUniversityListSuccess: (state, action) => {
            return { ...state, universityMessage: "Universities fetched successfully", error: false, isUniversity: true, countryUniversityList: action.payload, universityList: [], loading: false };
        },
        resetUniversities: (state) => {
            return { ...state, universityList: [], counUniversityList: [], countryUniversityList: [], loading: true }
        }

    }
})

export const { universityListStart, universityListSuccess, counUniversityListSuccess, countryUniversityListSuccess, resetUniversities } = universityListSlice.actions;

export default universityListSlice.reducer;