//changes for Quill Js.
// import React, { useEffect, useRef, useState } from 'react';
// import { Editor } from '@tinymce/tinymce-react';
// import MobMenuHeader from '../../components/MobMenuHeader'
// import UnivLeftSidebar from '../../components/UnivLeftSidebar'
// import Breadcrump from '../../components/Structures/Breadcrump/Breadcrump'
// import Footer from '../../components/Footer'
// import ToastContainer from '../../components/ToastContainer'
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import MergeTagModal from '../../components/Modals/MergeTagModal';
// import { useDispatch, useSelector } from 'react-redux';
// import { createTemplate, getTemplate } from '../../@redux/dispatch/template/templateContent';
// import TinyMCEeditor from '../../components/TinyMCEeditor';
// import { getMergeTagsFieldsDefaults } from '../../@redux/dispatch/template/GetMergeTagsDispatch';
// import { getCustomTags } from '../../@redux/dispatch/template/customMergeTags';
// import Loader from '../../components/Loader';

// function CourseOfferLetter() {
//     function rgbToHex(inputString) {
//         // Regular expression to match RGB values
//         var rgbRegex = /rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g;

//         // Function to convert decimal to hexadecimal
//         function decimalToHex(decimal) {
//             var hex = decimal.toString(16);
//             return hex.length == 1 ? "0" + hex : hex;
//         }

//         // Replace each RGB value with its hexadecimal equivalent
//         var modifiedString = inputString.replace(rgbRegex, function (match, r, g, b) {
//             var hexR = decimalToHex(parseInt(r));
//             var hexG = decimalToHex(parseInt(g));
//             var hexB = decimalToHex(parseInt(b));
//             return "#" + hexR + hexG + hexB;
//         });

//         return modifiedString;
//     }

//     const defaultMergeTagsFields = useSelector((state) => state?.getMergeTagsDefaults?.mergeTags?.fields) || []
//     const customMergeTagsFields = useSelector((state) => state?.customTags?.customMergeTags?.data?.fields)
//     const templateContent = useSelector((state) => state.templateContent?.templateContent)
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const course_id = location?.state?.course_id;
//     console.log("Template content here is:-", templateContent)
//     console.log("Default merge tags from apis are:-", defaultMergeTagsFields)
//     console.log("All custom tags are ", customMergeTagsFields)
//     //added for quill js
//     const [editorContent, setEditorContent] = useState("");

//     const handleContentChange = (content) => {
//         setEditorContent(content);
//     };
//     // console.log("Custom merge tags from apis are:-", customMergeTagsFields)
//     const dispatch = useDispatch()
//     const navigate = useNavigate()
//     useEffect(() => {
//         if (!course_id) {
//             navigate('/courses')
//         }
//     }, [course_id])

//     const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
//     const [showEditor, setShowEditor] = useState(false);
    
//     const openModal = (e) => {
//         e.preventDefault();
//         setIsMergeModalOpen(true);
//     };

//     const closeModal = () => {
//         setIsMergeModalOpen(false);
//     };

//     const breadcrumps = [
//         { text: 'Home', path: '/dashboard' },
//         { text: 'All Courses', path: '/courses' },
//         { text: 'Course Offer Letter', path: '/courseOfferLetter' }
//     ]

//     const editorRef = useRef(null);
//     const handlerBtn = (e) => {
//         e.preventDefault();
//         if (editorRef.current) {
//             const content = editorRef.current.getEditor().root.innerHTML; // Get HTML content
//             const mergeTags = content.match(/{{(.*?)}}/g);
//             console.log("The whole content is :- ", rgbToHex(content));
//             console.log("List of merge Tags", mergeTags)
//             dispatch(createTemplate({
//                 course_id: course_id,
//                 content: rgbToHex(content),

//             }))
//         }
//     };

//     const [defaultMergeObjects, setDefaultMergeObjects] = useState([
//         { id: 145, value: 'First Name', title: 'First Name', },
//         { id: 678, value: 'Last Name', title: 'Last Name' },
//         { id: 987, value: 'Emailing', title: 'Email' }
//     ])

//     const transformedMergeObjects = defaultMergeTagsFields?.map((tag, index) => ({
//         id: index, // or you can use tag.id if it exists
//         value: tag.field_name,
//         title: tag.field_name,
//         data_type: tag.data_type
//     }));

//     const [transformedCustomMergeObject, setTransformedCustomMergeObject] = useState([])

//     useEffect(() => {
//         if (customMergeTagsFields?.length) {
//             setTransformedCustomMergeObject(customMergeTagsFields?.map((tag, index) => ({
//                 id: index,
//                 value: tag.field_name,
//                 title: tag.field_name,
//                 data_type: tag.data_type
//             })))
//         }
//     }, [customMergeTagsFields?.length])


//     console.log("transformedMergeObject is", transformedMergeObjects)
//     console.log("transformedCustomMergeObject is", transformedCustomMergeObject)

//     useEffect(() => {
//         setShowEditor(false)
//         setDefaultMergeObjects(defaultMergeTagsFields)
//         setShowEditor(true)
//     }, [defaultMergeTagsFields?.length])

//     const [combineTags1, setCombineTags] = useState([])

//     const combineTags = useRef([
//         { id: 145, value: 'First Name', title: 'First Name', },
//         { id: 678, value: 'Last Name', title: 'Last Name' },
//         { id: 987, value: 'Emailing', title: 'Email' }
//     ])

//     const [mergeTagsObject, setMergeTagsObject] = useState([])

//     useEffect(() => {
//         console.log("Merge tags object", mergeTagsObject)
//     }, [mergeTagsObject])

//     const handleAiRequest = (request, respondWith) => {
//         respondWith.string(() => Promise.reject("See docs to implement AI Assistant"));
//     };

//     useEffect(() => {
//         if (defaultMergeObjects?.length >= 0 && mergeTagsObject?.length >= 0) {
//             setShowEditor(false)
//             console.log("after adding value inside merge tag values are", transformedCustomMergeObject)
//             combineTags.current = [...transformedMergeObjects,
//             ...transformedCustomMergeObject,
//             ...mergeTagsObject]
//             console.log("All my combined tags are hehehehe", combineTags.current)
//             setTimeout(() => {
//                 setShowEditor(true)
//             }, 1000)
//         }
//     },
//         [mergeTagsObject?.length, transformedCustomMergeObject?.length])
//     // [mergeTagsObject.length])


//     // useEffect(() => {
//     //     setShowEditor(false);
//     //     setShowEditor(true);
//     //     return () => {
//     //         if (editorRef.current) {
//     //             editorRef.current.remove();

//     //         }
//     //     };
//     // }, []);
//     useEffect(() => {
//         setShowEditor(false);
//         setTimeout(() => setShowEditor(true), 0); // Ensures re-render after state update
    
//         return () => {
//             if (editorRef.current) {
//                 editorRef.current = null; // Reset reference instead of calling remove()
//             }
//         };
//     }, []);
    

//     useEffect(() => {
//         dispatch(getMergeTagsFieldsDefaults())
//         dispatch(getCustomTags({ id: course_id }))
//         dispatch(getTemplate({ id: course_id }))
//     }, [])

//     return (
//         <>
//             {/* <Loader /> */}
//             {/* <form method="post" action="" id="filter-form"> */}
//             <form method="post" action="" >
//                 <div className="col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10">
//                     <div className="course-grid">
//                         <div className="desktop">
//                             <div className="fixed-header sticky-top w-100">
//                                 <Breadcrump items={breadcrumps} />
//                                 {/* <Breadcrumb data={{ main: "Home", subdivision: "All Courses", backurl: "/dashboard" }} /> */}
//                                 <div className="row clearfix">

//                                 </div>
//                             </div>
//                         </div>
//                         <div className="box-white">
//                             <div className="d-flex justify-content-between mb-3">
//                                 <div className="col-sm-6 ">
//                                     <h4 className='pt-3'>New Editor</h4>
//                                 </div>
//                                 <div className="col-sm-6 text-end">
//                                     {/* id="manageMergeTagActionBtn" */}
//                                     <button className="btn btn-theme mr-btm-15 text-right" onClick={openModal}>Manage merge tags</button>
//                                 </div>
//                             </div>
//                             <div className='h-100 textarea' id='my_div_for_toolbar'>

//                                 {showEditor &&
//                                     // <TinyMCEeditor combineTags={combineTags} editorRef={editorRef} />
//                                     <TinyMCEeditor combineTags={combineTags} editorRef={editorRef} onContentChange={handleContentChange} />
//                                 }
//                             </div>
//                             <div className="row text-end mt-3">
//                                 <div className="col-sm-12">
//                                     <button id="saveTemplateActionBtn" className="btn btn-theme mr-top-15 text-right"
//                                         onClick={handlerBtn}
//                                     >Save template</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     {/* <Footer /> */}
//                 </div>
//             </form>

//             <ToastContainer />
//             <MergeTagModal isOpen={isMergeModalOpen} closeModal={closeModal} setInputOptions={setMergeTagsObject} />
//         </>
//     )
// }

// export default CourseOfferLetter



//TinyMcr code.
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import MobMenuHeader from '../../components/MobMenuHeader'
import UnivLeftSidebar from '../../components/UnivLeftSidebar'
import Breadcrump from '../../components/Structures/Breadcrump/Breadcrump'
import Footer from '../../components/Footer'
import ToastContainer from '../../components/ToastContainer'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MergeTagModal from '../../components/Modals/MergeTagModal';
import { useDispatch, useSelector } from 'react-redux';
import { createTemplate, getTemplate } from '../../@redux/dispatch/template/templateContent';
import TinyMCEeditor from '../../components/TinyMCEeditor';
import { getMergeTagsFieldsDefaults } from '../../@redux/dispatch/template/GetMergeTagsDispatch';
import { getCustomTags } from '../../@redux/dispatch/template/customMergeTags';
import Loader from '../../components/Loader';

function CourseOfferLetter() {
    function rgbToHex(inputString) {
        // Regular expression to match RGB values
        var rgbRegex = /rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g;

        // Function to convert decimal to hexadecimal
        function decimalToHex(decimal) {
            var hex = decimal.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        // Replace each RGB value with its hexadecimal equivalent
        var modifiedString = inputString.replace(rgbRegex, function (match, r, g, b) {
            var hexR = decimalToHex(parseInt(r));
            var hexG = decimalToHex(parseInt(g));
            var hexB = decimalToHex(parseInt(b));
            return "#" + hexR + hexG + hexB;
        });

        return modifiedString;
    }

    const defaultMergeTagsFields = useSelector((state) => state?.getMergeTagsDefaults?.mergeTags?.fields) || []
    const customMergeTagsFields = useSelector((state) => state?.customTags?.customMergeTags?.data?.fields)
    const templateContent = useSelector((state) => state.templateContent?.templateContent)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = location?.state?.course_id;
    console.log("Template content here is:-", templateContent)
    console.log("Default merge tags from apis are:-", defaultMergeTagsFields)
    console.log("All custom tags are ", customMergeTagsFields)
    // console.log("Custom merge tags from apis are:-", customMergeTagsFields)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (!course_id) {
            navigate('/courses')
        }
    }, [course_id])

    const [isMergeModalOpen, setIsMergeModalOpen] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const openModal = (e) => {
        e.preventDefault();
        setIsMergeModalOpen(true);
    };

    const closeModal = () => {
        setIsMergeModalOpen(false);
    };

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Courses', path: '/courses' },
        { text: 'Course Offer Letter', path: '/courseOfferLetter' }
    ]

    const editorRef = useRef(null);
    const handlerBtn = (e) => {
        e.preventDefault();
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            const mergeTags = content.match(/{{(.*?)}}/g);
            console.log("The whole content is :- ", rgbToHex(content));
            console.log("List of merge Tags", mergeTags)
            dispatch(createTemplate({
                course_id: course_id,
                content: rgbToHex(content),

            }))
        }
    };

    const [defaultMergeObjects, setDefaultMergeObjects] = useState([
        { id: 145, value: 'First Name', title: 'First Name', },
        { id: 678, value: 'Last Name', title: 'Last Name' },
        { id: 987, value: 'Emailing', title: 'Email' }
    ])

    const transformedMergeObjects = defaultMergeTagsFields?.map((tag, index) => ({
        id: index, // or you can use tag.id if it exists
        value: tag.field_name,
        title: tag.field_name,
        data_type: tag.data_type
    }));

    const [transformedCustomMergeObject, setTransformedCustomMergeObject] = useState([])

    useEffect(() => {
        if (customMergeTagsFields?.length) {
            setTransformedCustomMergeObject(customMergeTagsFields?.map((tag, index) => ({
                id: index,
                value: tag.field_name,
                title: tag.field_name,
                data_type: tag.data_type
            })))
        }
    }, [customMergeTagsFields?.length])


    console.log("transformedMergeObject is", transformedMergeObjects)
    console.log("transformedCustomMergeObject is", transformedCustomMergeObject)

    useEffect(() => {
        setShowEditor(false)
        setDefaultMergeObjects(defaultMergeTagsFields)
        setShowEditor(true)
    }, [defaultMergeTagsFields?.length])

    const [combineTags1, setCombineTags] = useState([])

    const combineTags = useRef([
        { id: 145, value: 'First Name', title: 'First Name', },
        { id: 678, value: 'Last Name', title: 'Last Name' },
        { id: 987, value: 'Emailing', title: 'Email' }
    ])

    const [mergeTagsObject, setMergeTagsObject] = useState([])

    useEffect(() => {
        console.log("Merge tags object", mergeTagsObject)
    }, [mergeTagsObject])

    const handleAiRequest = (request, respondWith) => {
        respondWith.string(() => Promise.reject("See docs to implement AI Assistant"));
    };

    useEffect(() => {
        if (defaultMergeObjects?.length >= 0 && mergeTagsObject?.length >= 0) {
            setShowEditor(false)
            console.log("after adding value inside merge tag values are", transformedCustomMergeObject)
            combineTags.current = [...transformedMergeObjects,
            ...transformedCustomMergeObject,
            ...mergeTagsObject]
            console.log("All my combined tags are hehehehe", combineTags.current)
            setTimeout(() => {
                setShowEditor(true)
            }, 1000)
        }
    },
        [mergeTagsObject?.length, transformedCustomMergeObject?.length])
    // [mergeTagsObject.length])


    useEffect(() => {
        setShowEditor(false);
        setShowEditor(true);
        return () => {
            if (editorRef.current) {
                editorRef.current.remove();

            }
        };
    }, []);

    useEffect(() => {
        dispatch(getMergeTagsFieldsDefaults())
        dispatch(getCustomTags({ id: course_id }))
        dispatch(getTemplate({ id: course_id }))
    }, [])

    return (
        <>
            {/* <Loader /> */}
            {/* <form method="post" action="" id="filter-form"> */}
            <form method="post" action="" >
                <div className="col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10">
                    <div className="course-grid">
                        <div className="desktop">
                            <div className="fixed-header sticky-top w-100">
                                <Breadcrump items={breadcrumps} />
                                {/* <Breadcrumb data={{ main: "Home", subdivision: "All Courses", backurl: "/dashboard" }} /> */}
                                <div className="row clearfix">

                                </div>
                            </div>
                        </div>
                        <div className="box-white">
                            <div className="d-flex justify-content-between mb-3">
                                <div className="col-sm-6 ">
                                    <h4 className='pt-3'>New Editor</h4>
                                </div>
                                <div className="col-sm-6 text-end">
                                    {/* id="manageMergeTagActionBtn" */}
                                    <button className="btn btn-theme mr-btm-15 text-right" onClick={openModal}>Manage merge tags</button>
                                </div>
                            </div>
                            <div className='h-100 textarea' id='my_div_for_toolbar'>

                                {showEditor &&
                                    <TinyMCEeditor combineTags={combineTags} editorRef={editorRef} />
                                }
                            </div>
                            <div className="row text-end mt-3">
                                <div className="col-sm-12">
                                    <button id="saveTemplateActionBtn" className="btn btn-theme mr-top-15 text-right"
                                        onClick={handlerBtn}
                                    >Save template</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </form>

            <ToastContainer />
            <MergeTagModal isOpen={isMergeModalOpen} closeModal={closeModal} setInputOptions={setMergeTagsObject} />
        </>
    )
}

export default CourseOfferLetter