import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { updateAgentStudentDetails } from '../../../@redux/dispatch/agents/UpdateAgentStudentDetailsReducer';
import { updateAgentCounsellorDetails } from '../../../@redux/dispatch/agents/UpdateAgentCounsellorDetailsReducer';

function CounsellorPersonalDetails({ setActiveTab }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search)
    const profile_id = queryParams.get('profile_id');
    const counsellorPersonalData = useSelector((state) => state?.agentCounsellorDetails?.counsellorDetails?.data?.[0])
    console.log("Counsellor Personal Data", counsellorPersonalData);

    const [personalFormData, setPersonalFormData] = useState({})

    useEffect(() => {
        console.log("agent's student related information", counsellorPersonalData)
        setPersonalFormData(counsellorPersonalData)
    }, [counsellorPersonalData])

    console.log("Student profile Id will be:-", profile_id)

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setPersonalFormData({
            ...personalFormData,
            [name]: value
        })
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        dispatch(updateAgentCounsellorDetails({ id: profile_id, formData: personalFormData }))
    }

    return (
        <div className="tab-pane fade show active"
            id="pills-studentpersonal"
            role="tabpanel"
            aria-labelledby="pills-studentpersonal-tab">
            <form
                onSubmit={handleUpdate}
            >
                <div className="form-container">
                    <div className="row gx-5 clearfix">
                        <div className="col-12 col-sm-6 col-md-7 col-lg-8 col-xl-9 col-xxl-9">
                            <h3 className="formTitle">
                                Quick start application with document upload
                            </h3>
                            <p className="formText">
                                We will read details from the document and
                                auto-fill your application form
                            </p>
                            <div className="form-group formLabel">
                                <label htmlFor="documentType">
                                    Choose Document Type
                                </label>
                                <select
                                    name="documentType"
                                    id="documentType"
                                    className="form-select"
                                >
                                    <option value="">Dropdown Select</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3">
                            <img
                                src="/images/form-img.png"
                                className="img-fluid"
                                alt="form icon"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-separator" />
                <div className="form-area">
                    <div className="form-head">
                        Your Personal Details{" "}
                        <span>We will use your personal details while processing your applications</span>
                    </div>
                    <div className="form-subhead">

                    </div>
                    <div className="row clearfix">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>First name</label>
                            <input
                                type="text"
                                id='First_Name'
                                name="First_Name"
                                className="form-control"
                                placeholder="First name"
                                value={personalFormData?.First_Name}
                                onChange={handleInputChange}
                            disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Last name</label>
                            <input
                                type="text"
                                id='Last_Name'
                                name="Last_Name"
                                className="form-control"
                                placeholder="Last name"
                                value={personalFormData?.Last_Name}
                                onChange={handleInputChange}
                            disabled
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2' htmlFor='dateBirth'>Date of birth</label>
                            <input
                                type="date"
                                id='Date_of_Birth'
                                name="Date_of_Birth"
                                className="form-control"
                                placeholder="Date_of_Birth"
                                value={personalFormData?.Date_of_Birth}
                                onChange={handleInputChange}
                            // onChange={handleInputChange}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Nationality</label>
                            <input
                                type="text"
                                id='Nationality'
                                name="Nationality"
                                className="form-control"
                                placeholder="Nationality"
                                value={personalFormData?.Nationality}
                                onChange={handleInputChange}
                            // onChange={handleInputChange}
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Email</label>
                            <input
                                type="email"
                                id='Email'
                                name="Email"
                                className="form-control"
                                placeholder="Enter your email"
                                value={personalFormData?.Email}
                                onChange={handleInputChange}
                            disabled
                            />
                        </div>

                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <label className='form-check-label mb-2'>Mobile number</label>
                            <div className="field-phone">
                                <span className="country-code">
                                    <img
                                        src="/images/icons/indian-flag.svg"
                                        className="img-fluid mCS_img_loaded"
                                        alt="indian flag goes here"
                                    />
                                    <select
                                        name="ccode"
                                        id="ccode"
                                        defaultValue={personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}
                                    >
                                        <option selected
                                            value={personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}
                                        >
                                            {personalFormData?.Phone?.slice(0, (personalFormData?.Phone?.length || 0) - 10)}
                                        </option>
                                    </select>
                                </span>
                                <input
                                    type="tel"
                                    name="Phone"
                                    id="Phone"
                                    className="form-control"
                                    placeholder="Mobile Number"
                                    value={personalFormData?.Phone?.slice((personalFormData?.Phone?.length || 0) - 10)}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container mt-5'>
                        <div className='row justify-content-end clearfix'>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                <button className="btn btn-theme me-1" onClick={() => { setActiveTab("counselloraddress") }}>Next</button>
                                <button className="btn btn-theme" type='submit' >Update profile</button>
                            </div>
                        </div>
                    </div>


                </div>

            </form>

        </div>
    )
}

export default CounsellorPersonalDetails