import axios from "axios";
import { getCourseStart, getCourseSuccess } from "../actions/GetCourses";
import { countryUniversityListSuccess, counUniversityListSuccess, resetUniversities, universityListStart, universityListSuccess } from "../actions/GetUniversities";
import apiUrl from "../ApiUrl";

export const getUniversity = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(universityListStart());
    dispatch(resetUniversities())
    try {
        const response = await axios.get(
            // `${apiUrl}/api/student/University/details`
            `${apiUrl}/api/student/University/list`
        );
        dispatch(universityListSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};

export const getCounUniversity = () => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(universityListStart());
    dispatch(resetUniversities())
    try {
        const response = await axios.get(
            // `${apiUrl}/api/counsellor/University/details`
            `${apiUrl}/api/counsellor/university/list`
        );
        dispatch(counUniversityListSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
};
export const universityCountryList = () => async (dispatch) => {
    try{
        const response = await axios.get(`${apiUrl}/api/university/country`)
        if(response && response.data && !response.data.error){
            console.log(response.data);
            dispatch(countryUniversityListSuccess(response.data));
        }
    }
    catch(error){
        console.log(error)
    }
}


