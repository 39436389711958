import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MobMenuHeader from '../MobMenuHeader';
import UnivLeftSidebar from '../UnivLeftSidebar';
import Breadcrump from '../Structures/Breadcrump/Breadcrump';
import Footer from '../Footer';
import { agentStudentDetailsId } from '../../@redux/dispatch/agents/GetAgentStudentDetailsReducer';
import axios from 'axios';
import { MdArrowOutward, MdArrowUpward, MdOutlineModeEditOutline } from 'react-icons/md';
import { SlNote } from "react-icons/sl";
import { getAgentCounsellors, getAgentStudents } from '../../@redux/dispatch/agents/GetAgentStudentsReducer';
import Loader from '../Loader';
import Skeleton from 'react-loading-skeleton';
import { agentCounsellorDetailsId } from '../../@redux/dispatch/agents/GetAgentCounsellorDetailsReducer';

function LoaderSkeleton() {
    return (
        <Skeleton count={10} className='py-3 my-1' />
    )
}

function NoDataFound() {
    return (
        <div className='title text-center '>
            <h2 className='text-primary'>It seems like no counsellors are added. Add using Add Counsellor button ! <MdArrowUpward size={100} className='text-danger' /></h2>
        </div>
    )
}

function Counsellors() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector((state) => state?.agentCounsellors?.agentCounsellorsList)
    const handleProfileUpdate = (Id) => {
        console.log("This counsellor id is:- ", Id)
        dispatch(agentCounsellorDetailsId(Id))
        navigate('counsellor-profile?profile_id=' + Id)
    }

    // const handleCreateApplication = (Id) => {
    //     dispatch(agentStudentDetailsId(Id))
    //     navigate('/applications/new-application?profile_id=' + Id)
    // }

    return (
        <table
            className="table table-borderless counsellor-data text-center courseScroll"
            id="counsellorList"
        >
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Action</th>
                </tr>
            </thead>
            {data?.map((counsellor, index) => (
                <tbody key={index} className='courseScroll'>
                    <tr>
                        <td data-title="Name"> {counsellor?.Name}</td>
                        <td data-title="Email">{counsellor?.Email}</td>
                        <td data-title="Phone">{counsellor?.Phone}</td>
                        <td>
                            <button className='bg-transparent border-0 me-3' title={`Edit ${counsellor?.Name}'s profile`} onClick={() => { handleProfileUpdate(counsellor?.id) }}>
                                <MdOutlineModeEditOutline size={22} style={{ color: '#706F85' }} />
                            </button>
                            {/* <button className='bg-transparent border-0' style={{ color: '#706F85' }} title={`Create application for ${counsellor?.Name}`} onClick={() => { handleCreateApplication(counsellor?.id) }}>
                                <SlNote size={22} style={{ color: '#706F85' }} />
                            </button> */}
                        </td>
                    </tr>
                </tbody>
            ))}
        </table>

    )
}


function CounsellorListAgent() {
    // const [data, setData] = useState([]);
    const [userData, setUserData] = useState("")
    const userName = useSelector((state) => (state?.config?.userRole?.Vendor_Name))
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const data = useSelector((state) => state?.agentCounsellors?.agentCounsellorsList)
    console.log("counsellor list data", data);
    const loading = useSelector((state) => state?.agentCounsellors?.loading)

       // Add refresh function
       const refreshData = useCallback(() => {
        setRefreshTrigger(prev => prev + 1);
    }, []);
    const handleProfileUpdate = (Id) => {
        console.log("This counsellor id is:- ", Id)
        dispatch(agentStudentDetailsId(Id))
        navigate('counsellor-profile?profile_id=' + Id)
    }

    const handleCreateApplication = (Id) => {
        dispatch(agentStudentDetailsId(Id))
        navigate('new-application?profile_id=' + Id)
    }

    const handleAddCounsellor = () => {
        navigate('add-counsellor')
    }

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'Counsellors ', path: '/counsellor-list' }
    ]

    // useEffect(() => {
    //     dispatch(getAgentStudents())
    // }, [dispatch])
    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                if (mounted) {
                    await dispatch(getAgentCounsellors());
                }
            } catch (error) {
                console.error("Error fetching counsellors:", error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            mounted = false;
        };
    }, [dispatch, refreshTrigger]);

    return (

        <div className="col-sm-12 col-md-9 ms-sm-auto col-lg-12 col-xl-10 col-xxl-10 ps-5 ps-lg-2">
            <div className="dashboard-right">
                <div className="desktop tablet">
                    <div className="row  top-header sticky-top clearfix">
                        <Breadcrump items={breadcrumps} />
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
                            <h2>Counsellors</h2>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-7 col-xxl-7 d-flex justify-content-end align-items-center">
                            {/* <div className="search-bar">
                                            <input
                                                type="text"
                                                name="search"
                                                id="search"
                                                className="search-area"
                                                placeholder="Search for Counsellors"
                                            />
                                        </div> */}
                            <button className="btn btn-purple" onClick={handleAddCounsellor}>
                                Add Counsellor
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row mobile clearfix">
                    <div className="col-12">
                        <h2>Hello,{userName}</h2>

                        <p className="subtext p-0">Welcome back!</p>
                    </div>
                </div>
                <div className="row clearfix">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="counsellor-table-box overflow-auto" style={{ height: '75vh' }}>
                            {loading && <LoaderSkeleton />}
                            {(!loading && data === undefined) && <NoDataFound />}
                            {data?.length > 0 && <Counsellors  data={data} onRefresh={refreshData}/>}
                            <nav className="my-5" aria-label="Page navigation example">
                                {/* <ul className="pagination justify-content-center">
                                                <li className="page-item">
                                                    <Link className="page-link">
                                                        <i className="bi bi-chevron-left" />
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link active" to="#">
                                                        1
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        2
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        3
                                                    </Link>
                                                </li>
                                                <li className="page-item">
                                                    <Link className="page-link" to="#">
                                                        <i className="bi bi-chevron-right" />
                                                    </Link>
                                                </li>
                                            </ul> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default CounsellorListAgent