import axios from "axios";
import { closeLoader, openLoader } from "../../actions/config";
import apiUrl from "../../ApiUrl";
import { updateAgentCounsellorDetailsStart, updateAgentCounsellorDetailsSuccess } from "../../actions/agents/UpdateAgentCounsellorDetails";

export const updateAgentCounsellorDetails = ({ id, formData }) => async (dispatch, getState) => {
    console.log("FORM DATA", formData);
    const baseUrl = getState().config.baseUrl
    dispatch(updateAgentCounsellorDetailsStart());
    dispatch(openLoader());
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'id': id,
                'credentials': 'include'
            },
            withCredentials: true
        };

        const response = await axios.put(`${apiUrl}/api/counsellor/update`, formData, config);
        dispatch(updateAgentCounsellorDetailsSuccess(response.data));
    } catch (error) {
        console.log(error)
    }
    finally {
        dispatch(closeLoader())
    }
};
