import axios from "axios";
import { agentAddStudentStart, agentAddStudentSuccess } from "../../../actions/agents/addStudent";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";
import { agentAddCounsellorStart, agentAddCounsellorSuccess } from "../../../actions/agents/addCounsellor";

// export const agentAddCounsellor = (body, navigate) => async (dispatch, getState) => {
//     const baseUrl = getState().config.baseUrl
//     console.log("body for agent counsellor creation", body);
//     dispatch(agentAddCounsellorStart());
//     dispatch(openLoader());
//     try {
//         const data = {
//             "First_Name": body.First_Name,
//             "Last_Name": body.Last_Name,
//             "Email": body.Email,
//             "Phone": body.Phone,
//             "Date_of_Birth": body.Date_of_Birth,
//             "Nationality": body.Nationality,
//             "ISD_Code": body.ISD_Code,
//             "Street_Address": body.Street_Address,
//             "Street_Address_Line_2": body.Street_Address_Line_2,
//             "City": body.City,
//             "Country": body.Country,
//             "Mailing_State": body.Mailing_State,
//             "Mailing_Zip": body.Mailing_Zip,
//             "Please_upload_photo_identification_of_yourself": "",
//             "profile": "counsellor"
//         };

//         const response = await axios.post(
//             `${apiUrl}/api/counsellor`,
//             data
//         );
        
//         if (response?.data) {
//             await dispatch(agentAddCounsellorSuccess(response.data));
//             if (navigate) {
//                 navigate('/counsellor-list');
//             }
//         }

//     } catch (error) {
//         console.error("Error creating counsellor:", error);
//     } finally {
//         dispatch(closeLoader());
//     }
// };

export const agentAddCounsellor = (body) => async (dispatch) => {
    console.log("Creating admin user for counsellor:", body);

    const headers = {
        'Content-Type': 'application/json'
    };

    const data = {
        "First_Name": body.First_Name,
        "Last_Name": body.Last_Name,
        "Email": body.Email,
        "Phone": body.Phone,
        "Date_of_Birth": body.Date_of_Birth,
        "Nationality": body.Nationality,
        "ISD_Code": body.ISD_Code,
        "Street_Address": body.Street_Address,
        "Street_Address_Line_2": body.Street_Address_Line_2,
        "City": body.City,
        "Country": body.Country,
        "Mailing_State": body.Mailing_State,
        "Mailing_Zip": body.Mailing_Zip,
        "Please_upload_photo_identification_of_yourself": "",
        "profile": "counsellor"
    };

    try {
        const response = await axios.post(
            `${apiUrl}/api/cognito/admin/create/user`,
            data,
            {
                headers: headers,
                redirect: 'follow',
                withCredentials: true // This will automatically handle cookies
            }
        );
        console.log("Admin user created successfully:", response.data);
        return response.data;
    } catch (error) {
        console.error("Failed to create admin user:", error);
        throw error;
    }
}


