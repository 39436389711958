import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { agentStudentDetailsId } from '../../../@redux/dispatch/agents/GetAgentStudentDetailsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentStudents } from '../../../@redux/dispatch/agents/GetAgentStudentsReducer';
import { getCounsellorStudents } from '../../../@redux/dispatch/counsellor/students';
import AgentStudentInfo from './AgentStudentInfo';
import CounsellorStudentInfo from './CounsellorStudentInfo';
import apiUrl from '../../../@redux/ApiUrl';

function StudentInfoModal({ isOpen, closeModal, isCourseName }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const agentStudent = useSelector((state) => (state.agentStudents))
    const userRole = useSelector((state) => (state?.config?.profile_id))

    const handleRowClick = (Id) => {
        dispatch(agentStudentDetailsId(Id))
        if (isCourseName != null) {
            navigate(`/applications/new-application?profile_id=${Id}&course_name=${isCourseName}`)
        } else {
            navigate("/applications/new-application?profile_id=" + Id)
        }
    }

    const fetchData = async (role) => {
        if (!role) return;
    
        const endpoint = role === "agent"
            ? "/api/agent/student-list"
            : role === "counsellor"
            ? "/api/counsellor/student-list"
            : "";
    
        if (!endpoint) return;
    
        try {
            const response = await axios.get(`${apiUrl}${endpoint}`);
            setData(response.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    
    useEffect(() => {
        let isCancelled = false;
    
        if (isOpen && userRole) {
            if (!isCancelled) {
                fetchData(userRole);
            }
        }
    
        return () => {
            isCancelled = true;
        };
    }, [isOpen, userRole]);
    

    const filteredData = data?.filter(student => {
        const searchStr = searchTerm.toLowerCase();
        return (
            student?.Full_Name?.toLowerCase().includes(searchStr) ||
            student?.Email?.toLowerCase().includes(searchStr) ||
            student?.Phone?.toString().includes(searchStr)
        );
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            contentLabel="New Application Modal"
            className="shadow-lg rounded-5 modal-lg"
        >
            <ModalHeader className='d-flex justify-content-center'>
                <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                />
                <div className="search-bar mb-5 border" >
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="search-area"
                        placeholder="Search for Students"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </ModalHeader>

            <ModalBody>
                <div className='overflow-auto'>
                    <div className="">
                        <div>
                            <table className='table table-bordered student-data text-center'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.map((student, index) => (
                                        <tr key={index} role='button' onClick={() => handleRowClick(student?.id)}>
                                            <td data-title="Name" className='text-nowrap'>{student?.Full_Name}</td>
                                            <td data-title="Email" className='text-nowrap'>{student?.Email}</td>
                                            <td data-title="Phone" className='text-nowrap'>{student?.Phone}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default StudentInfoModal