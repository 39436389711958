import axios from "axios";
import { agentApplicationStart } from "../../../actions/agents/agentApplication/agentCreateApplication";
import { closeLoader, openLoader } from "../../../actions/config";
import apiUrl from "../../../ApiUrl";

export const agentCreateNewApplication = (body, setActiveTab) => async (dispatch, getState) => {
    const baseUrl = getState().config.baseUrl
    dispatch(agentApplicationStart())
    dispatch(openLoader());
    console.log("body:", body);
    try {
        const headers = {
            'student-id': body.id,
            'course-id': body?.formData?.course_id,
            'Content-Type': 'application/json'
        };

        const response = await axios.post(`${apiUrl}/api/agent/create/application`, body, {
            headers,
            redirect: 'follow'
        });

        if (response) {
            console.log(response.data)
            alert("Application saved successfully")
            setActiveTab('documents')
        } else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
};