import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addCounsellorMessage: "",
    error: false,
    agentAddCounsellorData: {}
}

export const agentAddCounsellorSlice = createSlice({
    name: 'agentAddCounsellor',
    initialState,
    reducers: {
        agentAddCounsellorStart: (state) => {
            return { ...state, addCounsellorMessage: "adding agent's counsellor  ...", error: false, agentAddCounsellorData: {} };
        },
        agentAddCounsellorSuccess: (state, action) => {
            return { ...state, addCounsellorMessage: "Agent's counsellor created successfully", error: false };
        },
        addCounsellorFirstPart: (state, action) => {
            return { ...state, addCounsellorFirstPart: action.payload }
        }

    }
})

export const { agentAddCounsellorStart, agentAddCounsellorSuccess, addCounsellorFirstPart } = agentAddCounsellorSlice.actions;

export default agentAddCounsellorSlice.reducer;