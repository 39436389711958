import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { updateStudent } from '../../../@redux/dispatch/students/updateStudent';
import { updateAgentStudentDetails } from '../../../@redux/dispatch/agents/UpdateAgentStudentDetailsReducer';
import { AdminCreateStudent, agentAddStudent } from '../../../@redux/dispatch/agents/addStudent';
import Loader from '../../Loader';
import { agentAddCounsellor } from '../../../@redux/dispatch/agents/addCounsellor';

function AgentAddCounsellorAddress({ setActiveTab }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const personalPartDatas = useSelector(state => state.agentAddCounsellor?.addCounsellorFirstPart)
    console.log("data coming from personal tab for creating counsellor", personalPartDatas)
    const [addressData, setAddressData] = useState({})

    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setAddressData({
            ...addressData,
            [name]: value
        })
    }

    const handleAddBtn = async(e) => {
        e.preventDefault();
        await dispatch(agentAddCounsellor({
            ...personalPartDatas,
            ...addressData
        }))
        navigate("/counsellor-list")

    }
    return (
        <>
            {/* <Loader /> */}
            <div
                className="tab-pane fade show active"
                id="pills-studentaddress"
                role="tabpanel"
                aria-labelledby="pills-studentaddress-tab"
            >
                <form
                    onSubmit={handleAddBtn}
                >

                    <div className="form-area">
                        <div className="form-head">
                            Your Address Details{" "}
                            <span>We will use your address details while processing your applications</span>
                        </div>
                        <div className="form-subhead">
                            <hr />
                        </div>
                        <div className="row clearfix">
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Street Address Line 1</label>
                                <input
                                    type="text"
                                    id='Street_Address'
                                    name="Street_Address"
                                    className="form-control"
                                    placeholder="Street Address Line 1"
                                    // value={addressFormData?.Street_Address}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Street Address Line 2</label>
                                <input
                                    type="text"
                                    id='Street_Address_Line_2'
                                    name="Street_Address_Line_2"
                                    className="form-control"
                                    placeholder="Street Address Line 2"
                                    // value={addressFormData?.Street_Address_Line_2}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>City</label>
                                <input
                                    type="text"
                                    name="City"
                                    id="City"
                                    className="form-control"
                                    placeholder="City"
                                    // value={addressFormData?.City}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>State/Province</label>
                                <input
                                    type="text"
                                    id='Mailing_State'
                                    name="Mailing_State"
                                    className="form-control"
                                    placeholder="State"
                                    // value={addressFormData?.Mailing_State}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Postal/Zip Code</label>
                                <input
                                    type="Number"
                                    id='Mailing_Zip'
                                    name="Mailing_Zip"
                                    className="form-control"
                                    placeholder="Postal Code"
                                    // value={addressFormData?.Mailing_Zip}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <label className='form-check-label'>Country</label>
                                <input
                                    type='text'
                                    name='Country'
                                    id='Country'
                                    className='form-control'
                                    placeholder='Country'
                                    // value={addressFormData?.Country}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className='container'>
                            <div className='row justify-content-end clearfix'>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end p-4 form-bottom" >
                                    <button className="btn btn-theme me-1" onClick={() => { setActiveTab("counsellorpersonal") }}>Previous</button>
                                    {/* <button className="btn btn-theme me-1" onClick={() => { setActiveTab("references") }}>Next</button> */}
                                    <button className="btn btn-theme" type='submit'>Add profile</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </form>
            </div>
        </>

    )
}

export default AgentAddCounsellorAddress