import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAgentCounsellors: false,
    agentCounsellorsMessage: "",
    error: false,
    agentCounsellorsList: [],
    loading: true
}

export const getAgentCounsellorsSlice = createSlice({
    name: 'getAgentCounsellors',
    initialState,
    reducers: {
        getAgentCounsellorsStart: (state) => {
            return { ...state, agentCounsellorsMessage: "Getting agent counsellors list started ...", error: false, };
        },
        getAgentCounsellorsSuccess: (state, action) => {
            return { ...state, agentCounsellorsMessage: "All agent counsellors fetched successfully", error: false, isAgentCounsellors: true, agentCounsellorsList: action.payload, loading: false };
        },
        resetAgentCounsellors: (state) => {
            return { ...state, agentCounsellorsList: [], loading: true }
        }
    }
})

export const { getAgentCounsellorsStart, getAgentCounsellorsSuccess, resetAgentCounsellors } = getAgentCounsellorsSlice.actions;

export default getAgentCounsellorsSlice.reducer;