import React, { useEffect, useState } from 'react';
import axios from "axios"
// import Header from '../../components/Header'
// import LeftSidebar from '../../components/LeftSidebar'
// import ToastContainer from '../../components/ToastContainer'
// import Application1Modal from '../../components/Modals/Application1Modal'
import { Link, useNavigate } from 'react-router-dom';
// import UnivLeftSidebar from '../../components/UnivLeftSidebar';
// import MobMenuHeader from '../../components/MobMenuHeader';
// import Footer from '../../components/Footer';
// import Breadcrumb from '../../components/Structures/Breadcrumb/Breadcrumb';
// import Sort from '../../components/Structures/SortBy/Sort';
// import { getStudentDetails } from '../../@redux/dispatch/students/getDetails';
import { useDispatch, useSelector } from 'react-redux';
// import { applicationDetails } from '../../@redux/dispatch/applications/GetApplicationDetailsReducer';
import MobMenuHeader from '../MobMenuHeader';
import UnivLeftSidebar from '../UnivLeftSidebar';
import Breadcrumb from '../Structures/Breadcrump/Breadcrump';
import Footer from '../Footer';
import Application1Modal from '../Modals/Application1Modal';
import ToastContainer from '../ToastContainer';
import { applicationDetails } from '../../@redux/dispatch/applications/GetApplicationDetailsReducer';
import { getStudentDetails } from '../../@redux/dispatch/students/getDetails';
import StudentInfoModal from '../Modals/StudentInfoModal';
import Breadcrump from '../Structures/Breadcrump/Breadcrump';
import Skeleton from 'react-loading-skeleton';
import { getAgentsApplications } from '../../@redux/dispatch/applications/GetApplicationsReducer';
import { MdArrowOutward } from 'react-icons/md';
import { agentStudentDetailsId } from '../../@redux/dispatch/agents/GetAgentStudentDetailsReducer';

function LoaderSkeleton() {
    return (
        <Skeleton count={10} className='py-3 my-1' />
    )
}

function NoDataFound() {
    return (
        <div className='title text-center '>
            <h2 className='text-primary'>Oops! It seems like no applications are submitted . Apply using new application button ! <MdArrowOutward size={100} className='text-danger' /></h2>
        </div>
    )
}



function Lists({ perPage }) {
    const data = useSelector((state) => (state?.applicationList?.agentApplicationsList?.data))
    console.log("Dataaaaa", data);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = perPage;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex);
    const nPage = Math.ceil(data.length / recordsPerPage);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleRowClick = (Id) => {
        // navigate("/create-application?application=" + student?.id)
        dispatch(agentStudentDetailsId(Id));
        dispatch(applicationDetails(Id))
        navigate("view?application=" + Id)

    }

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        if (currentPage !== nPage) {
            setCurrentPage(currentPage + 1);
        }
    }

    const changeCPage = (Id) => {
        setCurrentPage(Id)
    }

    return (

        <div className='position-relative'>
            <table className="table table-borderless course-table w-100 responsive-tables">
                <thead>
                    <tr>
                        <th> Student </th>
                        <th>Course Name</th>
                        <th>Country</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th> Offer Letter</th>
                    </tr>
                </thead>
                <tbody  >
                    {
                        records?.map((student) => (
                            <tr className='' key={data.id} role='button' onClick={() => { handleRowClick(student?.id) }}>
                                <td>
                                    {student?.Contact_Name["name"]}
                                </td>
                                <td data-title="Course" >
                                    {student?.Select_Program?.name}
                                    <span>{student?.University_Name}</span>
                                </td>
                                <td data-title="Country">
                                    {student?.Country}
                                    {/* <span>{student.Country}</span> */}
                                </td>
                                <td data-title="Duration">{student?.Course_Duration}</td>
                                <td data-title="Status">{student?.Stage}</td>
                                <td data-title="OfferLetter" className=''>
                                    <button className={`btn btn-purple  ${student?.Offer_Letter_Student === null ? 'disabled' : ''}`}>
                                        View
                                    </button>
                                </td>
                            </tr>

                        ))}
                </tbody>
            </table>

            {
                data?.length > 4 ?
                    <nav className=" position-absolute start-50 translate-middle-x mb-2" style={{ bottom: '-70px' }}>
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Previous" onClick={prevPage}>
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {/* <li class="page-item"><a class="page-link" href="#" onClick={() => changeCPage(1)}>1</a></li> */}
                            {/* <li class="page-item"><a class="page-link" href="#" onClick={() => changeCPage(2)}>2</a></li> */}
                            <li class="page-item">
                                <a class="page-link" href="#" aria-label="Next" onClick={nextPage}>
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    :
                    null
            }

        </div>
    )
}

function ApplicationsListAgent() {
    // const [data, setData] = useState([]);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const data = useSelector((state) => (state?.applicationList?.agentApplicationsList?.data))
    const noData = useSelector((state) => (state?.applicationList?.agentApplicationsList))
    const loading = useSelector((state) => (state?.applicationList?.loading))

    console.log("data for agent in application page is", data)
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const breadcrumps = [
        { text: 'Home', path: '/dashboard' },
        { text: 'All Applications', path: '/applications' }
    ]

    useEffect(() => {
        dispatch(getAgentsApplications());
        dispatch(getStudentDetails())
    }, []);

    const handleRecordsChanges = (e) => {
        setRecordsPerPage(parseInt(e.target.value, 10));
    }

    return (
        <>

            <div className="col-xl-10 col-xxl-10 col-md-9 ms-sm-auto col-lg-12">
                <div className="course-grid ">
                    <div className="desktop tablet">
                        <div className="fixed-header sticky-top w-100 clearfix">
                            <Breadcrump items={breadcrumps} />
                            {/* <Breadcrumb data={{ main: "Home", subdivision: "All Applications", backurl: "/dashboard" }} /> */}
                            <div className="row align-items-center clearfix">
                                <div className="col-9 col-lg-6">
                                    <h5 className="course-title">Your Applications ({data && data?.length}) </h5>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="desktop">
                                        <div className="d-flex align-items-center gap-2">
                                            <label htmlFor="records" className="col-form-label mb-0 text-nowrap">
                                                Records Per Page
                                            </label>
                                            <select
                                                className="form-select form-select-sm mb-0"
                                                style={{ padding: "0.25rem", width: "60px", }}
                                                name="records"
                                                id="records"
                                                onChange={handleRecordsChanges}
                                            >
                                                <option value={5}>5</option>
                                                <option value={10}>10</option>
                                                <option value={15}>15</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 col-lg-3 text-end">
                                    <Link className="btn btn-purple" onClick={openModal}>
                                        New Application
                                    </Link>
                                    <StudentInfoModal isOpen={isModalOpen} closeModal={closeModal} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center mobile mb-3 clearfix">
                        <div className="col-8">
                            <h5 className="course-title-mobile">Your Applications</h5>
                        </div>
                        <div className="col-4 text-end">
                            <Link
                                // to="javascript:void(0)"
                                to="/create-application"
                                className="btn btn-purple"
                            // data-bs-toggle="modal"
                            // data-bs-target="#application-step-1"
                            >
                                New
                            </Link>
                        </div>
                    </div>
                    <div className="box-white applicationScroll">
                        {loading && <LoaderSkeleton />}
                        {(!loading && data?.length > 0) ?
                            <Lists perPage={recordsPerPage} /> :
                            <NoDataFound />
                        }
                        {/* {(!loading && data === undefined) && <NoDataFound />} */}
                        {/* {(data?.length > 0) && <Lists />} */}

                    </div>
                </div>
                <ul className="bottom-menu">
                    <li>
                        <Link to="/dashboard">
                            <i className="bi bi-house-fill" />{" "}
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="#" id="search-icon">
                            <i className="bi bi-search" />
                            Search
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile">
                            <i className="bi bi-person-fill" />
                            Profile
                        </Link>
                    </li>
                    <li>
                        <Link
                            data-bs-toggle="offcanvas"
                            to="#mobileMenu"
                            role="button"
                            aria-controls="mobileMenuExample"
                        >
                            <i className="bi bi-list" />
                            Menu
                        </Link>
                    </li>
                </ul>

            </div>

            {/* Modal */}
            <Application1Modal />
            <ToastContainer />
        </>

    )
}

export default ApplicationsListAgent