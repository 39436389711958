import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isCourseAttribute: false,
    courseAttributeMessage: "",
    error: false,
    courseDurationList:[],
    courseLevelList:[],
    loading: true,
}

export const courseAttributeListSlice = createSlice({
    name: 'courseAttributeList',
    initialState,
    reducers: {
        courseDurationListStart: (state) => {
            return { ...state, courseAttributeMessage: "Fetching course attribute started...", error: false, loading: true };
        },
        courseDurationListSuccess: (state, action) => {
            return { ...state, courseAttributeMessage: "Course attribute fetched successfully", error: false, isCourseAttribute: true, courseDurationList: action.payload, loading: false };
        },
        courseLevelListStart: (state) => {
            return { ...state, courseAttributeMessage: "Fetching course attribute started...", error: false, loading: true };
        },
        courseLevelListSuccess: (state, action) => {
            return { ...state, courseAttributeMessage: "Course attribute fetched successfully", error: false, isCourseAttribute: true, courseLevelList: action.payload, loading: false };
        },
        resetCourseAttributeList: (state) => {
            return { ...state, courseDurationList: [], courseLevelList: [], loading: true }
        }

    }
})

export const { courseDurationListStart, courseDurationListSuccess, courseLevelListStart, courseLevelListSuccess, resetCourseAttributeList } = courseAttributeListSlice.actions;

export default courseAttributeListSlice.reducer;