import axios from "axios";
import apiUrl from "../../ApiUrl";
import { course_details, created_course } from "../../actions/course";
import toastr from "toastr";
import { closeLoader, openLoader } from "../../actions/config";
import { getCounsellorCourses } from "../GetCoursesReducer";
import { courseDurationListStart, courseDurationListSuccess, courseLevelListSuccess } from "../../actions/GetCourseAttributeList";

export const createCourse = (body, closeModal) => async (dispatch, getState) => {
    dispatch(openLoader())
    try {

        const response = await axios.post(`${apiUrl}/api/crm/create/course`, body);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for created courses in dispatch", response.data)
            dispatch(created_course(response.data));
            dispatch(getCounsellorCourses())
            closeModal();


        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }

};

export const getCourseDetailsId = (Id) => async (dispatch) => {
    dispatch(openLoader())
    try {

        const response = await axios.get(`${apiUrl}/api/course/details?courseid=${Id}`);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for course detail by Id in dispatch", response.data)
            dispatch(course_details(response.data))
            // dispatch(universityDetails(response.data))

        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const updateCourse = (body, closeModal) => async (dispatch) => {
    dispatch(openLoader())
    try {

        const response = await axios.put(`${apiUrl}/api/crm/update/course`, body);
        if (response && response.data && !response.data.error) {
            console.log("response i am getting for creating university list in dispatch", response.data)
            // dispatch(universityList(response.data))
            dispatch(getCounsellorCourses())
            closeModal();

        }
        else if (response && response.data && !response.data.error) {
            toastr.error(response.data.error)
        }
        else {
            throw new Error('No data received from the server');
        }
    }
    catch (error) {
        console.log("error is here:- ", error);
    }
    finally {
        dispatch(closeLoader())
    }
}

export const getCourseAttributeList = () => async (dispatch) => {
    dispatch(courseDurationListStart())
    try {
        const response = await axios.get(`${apiUrl}/api/course/attribute/filter`)
        if(response && response.data && !response.data.error){
            const {durations, courseLevels} = response.data;
            dispatch(courseDurationListSuccess(durations))
            dispatch(courseLevelListSuccess(courseLevels))
        }
        else{
            throw new Error('No data received from the server')
        }
    } catch (error) {
        console.log(error)
    }
}   
