import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterCounUniversity, filterUniversity, resetCounUniversity, resetUniversity } from '../../../@redux/dispatch/filters';
import { universityCountryList } from '../../../@redux/dispatch/GetUniversitiesReducer';

function Filters({ userRole }) {
    const [destination, setDestination] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const dispatch = useDispatch();
    const [countries, setCountries] = useState([]);
    const countryList = useSelector((state) => state?.universityList?.countryUniversityList?.countries);

    useEffect(() => {
        dispatch(universityCountryList());
    }, [dispatch]);

    useEffect(() => {
        if (Array.isArray(countryList)) {
            setCountries(countryList);
        }
    }, [countryList]);

    const handleDestinationChange = (e) => {
        const { name, checked } = e.target;
        const newDestination = checked ? [...destination, name] : destination.filter(dest => dest !== name);
        setDestination(newDestination);
        if (userRole !== 'counsellor') {
            dispatch(filterUniversity({ destination: newDestination }));
        } else {
            dispatch(filterCounUniversity({ destination: newDestination }));
        }
    };

    const handleReset = (e) => {
        e.preventDefault();
        setDestination([]);
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false);
        if (userRole !== 'counsellor') {
            dispatch(resetUniversity());
        } else {
            dispatch(resetCounUniversity());
        }
    };

    const renderCountries = () => {
        if (!Array.isArray(countries)) return null;
        const displayCountries = showMore ? countries : countries.slice(0, 4);
        return displayCountries.map((country, index) => (
            <div className="form-check" key={index}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    name={country}
                    id={country}
                    onChange={handleDestinationChange}
                />
                <label className="form-check-label" htmlFor={country}>
                    {country}
                </label>
            </div>
        ));
    };

    return (
        <div className="col-12 col-lg-12 col-xl-2 col-xxl-2 filter-desktop customize-mobile p-1">
            <div className="f-title">
                <span id="filter-close">
                    <i className="bi bi-chevron-left" />
                </span>{" "}
                Filters
                <span className="reset-button desktop">
                    <input type="button" name="reset" defaultValue="Reset" onClick={handleReset} />
                </span>
            </div>
            <div className="form-fields">
                <h6 className="filter-title">Destination</h6>
                <div className="checkboxes filter-checkboxes">
                    {renderCountries()}
                    {countries?.length > 4 && (
                        <button 
                            type="button"
                            style={{
                                border: 'none',
                                background: 'none',
                                color: '#6c63ff',
                                fontSize: '0.9rem',
                                padding: '4px 0',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowMore(!showMore)}
                        >
                            {showMore ? 'Show Less' : 'Show More'}
                            <i className={`bi bi-chevron-${showMore ? 'up' : 'down'}`} />
                        </button>
                    )}
                </div>
                {/* <button className='btn btn-purple' type='submit'>Search Results</button> */}
            </div>
        </div>
    );
}

export default Filters;
